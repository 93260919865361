import { Env } from '@/enums/envEnums'

export interface ResponseData<T> {
    code?: number
    msg?: string
    page?: Page
    data?: T
    error?: string
}

export interface Field {
    columns?: string[]
    sorts?: string[]
    filters?: string[]
    args?: any[]
}

export interface Device {
    platform?: string
    ip?: string
    device_no?: string
}

export interface App {
    p_id?: string
    o_id?: string
}

export interface RequestData<T> {
    page?: Page
    data?: T
    field?: Field
    device?: Device
    app?: App
}

export interface Page {
    current?: number
    size?: number
    total?: number
}
export function Encode<T>(data: T, page: Page): RequestData<T> {
    return {
        device: {},
        page: page,
        field: {},
        data: data,
        app: {
            p_id: Env.p_id,
            o_id: Env.o_id
        }
    }
}
