export default {
    绑定邮箱: 'Bind email',
    请输入邮箱: 'Please enter your email address',
    请输入正确的邮箱: 'Please enter the correct email address',
    请绑定手机号: 'Please bind your mobile phone number',
    绑定微信: 'Bind WeChat',
    绑定后即可用该微信扫码登录账号: 'You can log in with this WeChat by scanning after binding',
    修改邮箱: 'Modify email',
    当前邮箱: 'Current email',
    换绑手机号: 'Change mobile phone number',
    '换绑后将无法使用该手机号登录此账号！': "You can't log in with this mobile phone number after changing! ",
    当前手机号: 'Current mobile number',
    文件云盘已用: 'The file cloud disk is used',
    共: 'general',
    会员权限: 'Member rights',
    账号剩余: 'Account Balance',
    续费: 'Renewal',
    本周上传文件数: 'Number of files uploaded this week',
    请输入激活码: 'Please enter activation code',
    联系客户经理购买激活码: 'Contact Customer Manager to purchase activation code',
    请输入正确的激活码: 'Please enter correct activation code',
    手机: 'Mobile phone number',
    修改手机: 'Modify mobile phone number',
    绑定手机: 'Bind mobile phone',
    邮箱: 'Email',
    密码: 'password',
    重置密码: 'Reset password',
    '是否解除绑定？': 'Do you want to unbind?',
    密码设置: 'password setting',
    请先绑定手机或者邮箱: 'Please bind your mobile phone number or email first ',
    手机号: 'mobile',
    设置成功: 'Setup successful',
    请选择图片上传: 'Please select an image to upload',
    个人账号剩余: 'Personal account remaining'
}
