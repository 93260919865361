export default {
    项目: '项目',
    客户: '客户',
    企业: '企业',
    资源: '资源',
    语言: '语言',
    个人: '个人',
    个人信息: '个人信息',
    退出登录: '退出登录',
    客户信息: '客户信息',
    联系人: '联系人',
    全屏展示: '全屏展示',
    取消全屏: '取消全屏',
    最新版本: '最新版本',
    项目栏目: '项目栏目'
}
