export default {
    请选择: '请选择',
    文件: '文件',
    文件不能大于50M: '文件不能大于50M',
    标记: '标记',
    '仅支持".pdf"格式文件': '仅支持".pdf"格式文件',
    微穴种子: '微穴种子',
    '仅支持".tif"格式文件': '仅支持".tif"格式文件',
    补偿曲线: '补偿曲线',
    '仅支持".cfcurve"格式文件': '仅支持".cfcurve"格式文件',
    '仅支持".json"格式文件': '仅支持".json"格式文件',
    刀模: '刀模',
    '仅支持".cf2"格式文件': '仅支持".cf2"格式文件',
    mark: '标记',
    seed: '微穴种子',
    dgc: '补偿曲线',
    arg: '功能参数',
    daomu: '刀模',
    'resources.10201.name': '标记',
    'resources.10202.name': '微穴种子',
    'resources.10203.name': '补偿曲线',
    'resources.10206.name': '刀模',
    'resources.10205.name': '功能参数',
    'resources.1020501.name': '预检',
    'resources.1020502.name': '陷印',
    'resources.1020503.name': '对比',
    'resources.1020504.name': '拼版',
    'resources.1020505.name': 'RIP',
    'resources.1020506.name': 'LenToTIFF',
    'resources.1020507.name': '审批',
    'resources.1020508.name': '智能柔印',
    'resources.1020509.name': '混拼',
    'resources.1020510.name': '画质增强',
    'resources.1020511.name': '3D光线追踪',
    'resources.1020512.name': '色彩转换',
    'resources.1020513.name': 'VDP',
    'resources.1020514.name': '软包订单拼版',
    'resources.1020517.name': '刀模拼版',
    'resources.1020518.name': 'Tiff精度转换'
}
