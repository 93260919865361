export default {
    语言设置: 'Language settings',
    使用教程: 'Usage Tutorial',
    其他方式登录: 'Other ways to log in',
    账号注册: 'Account registration',
    返回登录: 'Back to Login',
    找回密码: 'Password recovery',
    '想起密码？': 'Remember your password?',
    去登录: 'Log in now',
    '微信扫码登录/注册': 'Scan with WeChat to log in or register',
    '验证登录/注册': 'Verify login/registration',
    请输入手机号: 'Please enter your mobile number',
    请输入正确的手机号: 'Pls enter the correct mobile number',
    请输入验证码: 'Please enter the verification code',
    秒后重新获取: 'Resend the verification code',
    获取验证码: 'Get the verification code',
    未注册手机号将自动创建账号: 'An account will be created automatically if the phone number is not registered',
    登录: 'Login',
    '发送成功,请填写收到的验证码': 'Send successfully, please fill in the received verification code',
    登录成功: 'Login successfully!',
    账号登录: 'Account login',
    '输入8-15位密码': 'Input a password with 8-15 characters',
    '忘记密码？': 'Forgot your password?',
    确认密码错误: 'Confirm password error',
    请确认密码: 'Please confirm the password',
    验证码已发送: 'The verification code has been sent',
    注册成功: '注册成功',
    '重置成功，请登录！': 'Reset successfully, please log in!',
    微信: 'WeChat',
    手机验证: 'Mobile verification',
    账号密码: 'Account password',
    '欢迎您登录印丫云！': 'Welcome to log in to YinYun!',
    绑定手机号: 'Bind mobile number',
    '手机号是否绑定新微信？': 'Whether the mobile phone number is bound to wechat?',
    '绑定手机号获得更高级的安全保护，同时您可以直接使用手机号登录印路所有产品，方便快捷':
        'By binding the mobile number, you can obtain more advanced security protection and directly log in to all Yinluo products using the mobile number, which is convenient and fast.',
    账号已注册: 'Account has been registered',
    登录或注册即同意: 'Agree to terms upon logging in or registering',
    平台服务协议: 'Platform service agreement',
    隐私政策: 'Privacy policy',
    和: 'And',
    法律声明: 'Legal statement',
    '欢迎您登录！': 'Welcome to login!',
    最新版本: 'Latest version'
}
