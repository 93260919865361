import axios from 'axios'
import { message } from 'ant-design-vue'
import cache from '../cache'
import { TOKEN_KEY } from '@/enums/cacheEnums'
import { ContentTypeEnum, RequestCodeEnum } from '@/enums/requestEnums'
import { Encode } from './baseType'
import { getLanguageValue, languageTypeEnum } from '@/config/ConfigType'
import { Env } from '@/enums/envEnums'
import i18n from '@/lang'
import { throttleLoginxpiration } from './utils'

export const request = axios.create({
    timeout: 30000,
    // 基础接口地址
    baseURL: Env.url_api,
    method: 'post',
    headers: {
        'Content-Type': ContentTypeEnum.JSON,
        version: Env.version
    }
})

request.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        // console.log("request.interceptors.request", config)

        var token = cache.get(TOKEN_KEY)
        // console.log("token", token)
        if (token != '' && token != undefined && config != undefined && config.headers != undefined) {
            config.headers['token'] = token
        }
        if (config.headers?.['Content-Type'] == ContentTypeEnum.JSON) {
            if (config.data != null) {
                if (config.data.page == null || config.data.data == null) {
                    config.data = Encode(config.data, config.data.page)
                } else if (config.data.page && config.data.app == null) {
                    config.data.app = {
                        p_id: Env.p_id,
                        o_id: Env.o_id
                    }
                }
            }
        }
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 返回拦截
request.interceptors.response.use(
    async function (response) {
        // console.log("=========================", response);

        let res: any
        if (response.headers['content-type'] == 'application/octet-stream;charset=UTF-8') {
            return response
        } else {
            // 导出失败之后的处理
            if (response.data.type == 'application/json') {
                res = JSON.parse(await response.data.text())
            } else {
                res = response.data
            }
        }
        switch (res.code) {
            case RequestCodeEnum.OK:
                return res

            case RequestCodeEnum.TOKEN_INVALID:
            case RequestCodeEnum.TOKEN_EMPTY:
                throttleLoginxpiration()
                // message.warning('登录已过期，请重新登录')
                // cache.remove(TOKEN_KEY)
                // setTimeout(() => {
                //     window.location.href = '/login'
                // }, 1000)
                break

            case RequestCodeEnum.SharedNeedPassworld:
            case RequestCodeEnum.SharedExpired:
            case RequestCodeEnum.ErrUserTimeout:
                break
            case undefined:
                break
            default:
                const localeMsg = getLanguageValue(res.code, languageTypeEnum.code)
                if (cache.get(TOKEN_KEY) != undefined) {
                    if (localeMsg != undefined && localeMsg != '') {
                        message.error(localeMsg)
                    } else {
                        message.error(res.msg)
                    }
                } else {
                    message.error(res.msg)
                }
        }
        return response.data
    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        if (error?.code !== 'ERR_CANCELED') {
            message.error(i18n.global.t('网络请求异常，请稍后重试!'))
        }
        return Promise.reject(error)
    }
)
