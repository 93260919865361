export default {
    方案信息: '方案信息',
    默认配置: '默认配置',
    配置ID: '配置ID',
    配置名称: '配置名称',
    功能名称: '功能名称',
    创建时间: '创建时间',
    操作: '操作',
    json文件格式错误: 'json文件格式错误',
    设置成功: '设置成功',
    取消成功: '取消成功',
    '请先选择功能！': '请先选择功能！',
    选择功能: '选择功能',
    表单设置: '表单设置',
    上一步: '上一步',
    下一步: '下一步',
    方案: '方案',
    上传json文件: '上传json文件',
    json文件内容错误: 'json文件内容错误',
    须同时选择: '须同时选择'
}
