export const Env = {
    version: import.meta.env.VITE_API_VERSION,
    server_host: import.meta.env.VITE_SERVER_HOST,
    url_api: import.meta.env.VITE_SERVER_HOST + import.meta.env.VITE_PATH_API,
    url_resource: import.meta.env.VITE_SERVER_HOST + import.meta.env.VITE_PATH_RESOURCE,
    url_resource_cf: import.meta.env.VITE_SERVER_HOST + import.meta.env.VITE_PATH_RESOURCE_CLOUDFLOW,
    url_ws: import.meta.env.VITE_WEBSOCKET_URL,

    env: import.meta.env.MODE,
    p_id: '3',
    o_id: '-3',

    path_cf: import.meta.env.VITE_CF_PATH
}
