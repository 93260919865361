export default {
    方案信息: 'Scheme information',
    默认配置: 'Default configuration',
    配置ID: 'Configuration ID',
    配置名称: 'Configuration name',
    功能名称: 'Function name',
    创建时间: 'Creation time',
    操作: 'operation',
    json文件格式错误: 'The json file format is incorrect',
    设置成功: 'Successful setting',
    取消成功: 'Cancel successfully',
    '请先选择功能！': 'Please select features first!',
    选择功能: 'Selection function',
    表单设置: 'Form Settings',
    上一步: 'Last step',
    下一步: 'next',
    方案: 'option',
    上传json文件: 'Upload json file',
    json文件内容错误: 'The json file content is incorrect',
    须同时选择: 'Must be selected simultaneously'
}
