import { reactive } from 'vue'
import { defineStore } from 'pinia'

export const useChannelAppStore = defineStore('channelAppStore', () => {
    const channelApp = reactive<any>({})
    const setChannelApp = data => {
        Object.assign(channelApp, data)
    }
    return { channelApp, setChannelApp }
})
