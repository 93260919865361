export default {
    '1010102': '預檢',
    '1010103': '陷印',
    '1010104': '對比',
    '1010105': '拼版',
    '1010106': 'RIP',
    '1010107': 'LenToTIFF',
    '1010108': '審批',
    '1010109': '智能柔印',
    '1010110': '混拼',
    '1010111': '畫質增強',
    '1010112': '3D光線追蹤',
    '1010113': '色彩轉換',
    '1010114': 'VDP',
    '1010115': '軟包訂單拼版',
    '1010116': '多頁PDF拆分',
    '1010117': '刀模拼版',
    '1010118': 'Tiff精度轉換',
    '1010119': '自動出血',
    '1010151': '智能摳圖',
    '1010153': '生成相似圖',
    '1010154': '智能擴圖',
    '1010155': '智能擦除',
    '1010156': '圖轉線稿',
    '1010157': '文生圖',
    '1010199': 'G2P',
    
    '00_原件': '原件',
    '01_预检': '預檢',
    预检文件: '預檢文件',
    预检报告: '預檢報告',
    '02_陷印': '陷印',
    '03_对比': '對比',
    对比记录: '對比記錄',
    对比报告: '對比報告',
    '04_拼版': '拼版',
    '05_RIP': 'RIP',
    '06_LenToTIFF': 'LenToTIFF',
    '07_审批': '審批',
    '08_智能柔印': '智能柔印',
    '09_混拼': '混拼',
    '10_画质增强': '畫質增強',
    '11_3D光线追踪': '3D光線追蹤',
    '12_色彩转换': '色彩轉換',
    '13_VDP': 'VDP',
    '14_软包订单拼版': '軟包訂單拼版',
    '16_多页PDF拆分': '多頁PDF拆分',
    '17_刀模拼版': '刀模拼版',
    '18_Tiff精度转换': 'Tiff精度轉換',
    '19_自动出血': '自動出血',
    '51_智能抠图': '智能摳圖',
    '53_图像联想': '生成相似圖',
    '54_智能扩图': '智能擴圖',
    '55_智能擦除': '智能擦除',
    '56_线稿': '圖轉線稿',
    '57_文生图': '文生圖',
    '99_G2P': 'G2P',

    '自动检测文件合规性，生成详尽的报告。': '自動檢測文件合規性，生成詳盡的報告。',
    '补偿的对位误差，防止出现未印刷的空隙。': '補償的對位誤差，防止出現未印刷的空隙。',
    '快速检测文件变化，并生成清晰的对比报告。': '快速檢測文件變化，並生成清晰的對比報告。',
    '轻松实现对文件的灵活拼贴和混排。': '輕鬆實現對檔的靈活拼貼和混排。',
    '进行自动化分色处理和个性化参数设置。': '進行自動化分色處理和個性化參數設置。',
    '便捷地将Len格式文件转换为TIFF格式。': '便捷地將Len格式檔轉換為TIFF格式。',
    '支持多渠道抄送确认，并保存在云端。': '支持多管道抄送確認，並保存在雲端。',
    '优化传统柔版印刷过程。': '優化傳統柔版印刷過程。',
    '轻松实现文件的多元化排列和组合。': '輕鬆實現檔的多元化排列和組合。',
    '提升印前文件的图像质量，确保印刷品质更为清晰。': '提升印前檔的圖像品質，確保印刷品質更為清晰。',
    对印前文件的三维图像高清光线渲染: '對印前檔的三維圖像高清光線渲染',
    '保证印刷品的色彩准确性和一致性。': '保證印刷品的色彩準確性和一致性。',
    '可变数据实现每个打印项的个性化和定制。': '可變資料實現每個列印項的個性化和定制。',
    '软包装订单及拼版自动化。': '軟包裝訂單及拼版自動化。',
    '把PDF文件，每页拆分成独立的PDF文件。': '把PDF文件，每頁拆分成獨立的PDF文件。',
    '按照输入的刀模文件，完成拼版。': '按照輸入的刀模檔，完成拼版。',
    'Tiff文件精度值按照输入参数转换。': 'Tiff檔精度值按照輸入參數轉換。',
    'AI识别图像中的主要实体，完成一键抠图。': 'AI識別圖像中的主要實體，完成一鍵摳圖。',
    '根据输入的图片，AI生成相似的图片。': '根據輸入的圖片，AI生成相似的圖片。',
    '根据输入的图像，智能扩展生成图片。': '根據輸入的圖像，智慧擴展生成圖片。',
    '根据输入的图像，生成线稿。': '根據輸入的圖像，生成線稿。',
    '根据输入的文本，生成图片': '根據輸入的文本，生成圖片',

    'program.StatusMap.create': '已創建',
    'program.StatusMap.doing': '處理中',
    'program.StatusMap.failure': '失敗',
    'program.StatusMap.success': '成功',
    'program.StatusMap.timeout': '已超時',
    'program.StatusMap.stop': '流程終止',
    'program.StatusMap.audit_success': '審核通過',
    'program.StatusMap.audit_failure': '審核通過',
    'program.StatusMap.send_success': '發送成功'
}
