import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import '@vue-flow/core/dist/style.css'
import '@vue-flow/core/dist/theme-default.css'
import './assets/css/main.css'
import App from './App.vue'
import router from './router'
import i18n from './lang'
import store from './stores'
import Init from '@/utils/init'

const app = createApp(App)
app.use(Init)
app.use(store)
app.use(router)
app.use(i18n)
app.use(Antd)
app.mount('#app')
