export default {
    到期时间: 'Expire date',
    您还未加入企业: 'You have not joined the company yet',
    人员信息: 'Personnel information',
    仅看自己印前项目: 'Only view your own pre-press projects',
    所有成员印前项目: 'All members prepress projects',
    我的客服: 'My customer service',
    工作日: 'working day',
    印前服务: 'Prepress services',
    本年已服务次数: 'Number of services served this year',
    服务内容包含: 'Service content includes',
    '1、精准的标记文件制作：包括切割线、折叠线和颜色校验条等，确保印刷过程中的准确性和效率。':
        '1. Precise marking file production: including cutting lines, folding lines and color calibration strips to ensure accuracy and efficiency during the printing process.',
    '2、高质量微穴种子制作：制作精细的屏幕点图案，以优化印刷品的质感和色彩深度。':
        '2. High-quality microcavity seed production: Produce fine screen dot patterns to optimize the texture and color depth of printed matter',
    '3、补偿曲线定制：会员定制补偿曲线，解决由纸张、油墨等因素引起的偏差，确保印刷输出忠实于原始设计。':
        '3. Compensation curve customization: Members customize the compensation curve to solve deviations caused by factors such as paper and ink, ensuring that the printed output is faithful to the original design.',
    '4、VDP可变数据文件制作：可变数据打印（VDP）文件制作服务，将会员客户的个性化数据集成到印刷物中。':
        "4. VDP variable data file production: Variable data printing (VDP) file production service integrates member customers'personalized data into printed matter."
}
