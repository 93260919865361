export default {
    '您的浏览器不支持播放声音。': '您的流覽器不支援播放聲音。',
    '您的浏览器不支持播放视频。': '您的流覽器不支援播放視頻。',
    印前项目: '印前項目',
    项目目录: '專案目錄',
    目录文件: '目錄檔',
    已选文件: '已選文件',
    上传原件: '上傳原件',
    '文件尺寸过大，无法上传': '文件尺寸過大，無法上傳',
    上传失败: '上傳失敗',
    修改参数: '修改參數',
    参数: '參數',
    原件: '原件',
    生成文件: '生成檔',
    历史记录: '歷史記錄',
    项目信息: '專案資訊',
    删除项目: '刪除項目',
    可选功能: '可選功能',
    更多功能: '更多功能',
    文件处理: '文件處理',
    '本功能暂未开启，请联系客户经理开启': '本功能暫未開啟，請聯繫客戶經理開啟',
    请选择文件: '請選擇檔',
    项目编号: '項目編號',
    项目类型: '項目類型',
    项目名称: '項目名稱',
    项目客户: '專案客戶',
    请输入项目名称: '請輸入項目名稱',
    联系人: '連絡人',
    版面参数: '版面參數',
    文件参数: '文件參數',
    文件默认参数: '文件默認參數',
    等: '等',
    个文件: '個文件',
    必须选择: '必須選擇',
    印刷方式: '印刷方式',
    陷印距离: '陷印距離',
    '最大值3mm,最小值-3mm': '最大值3mm,最小值-3mm',
    标记: '標記',
    宽度: '寬度',
    '最大值3000mm,最小值20mm': '最大值3000mm,最小值20mm',
    宽度不能为负数: '寬度不能為負數',
    咬口: '咬口',
    '最大值30mm,最小值0mm': '最大值30mm,最小值0mm',
    咬口不能为负数: '咬口不能為負數',
    高度: '高度',
    高度不能为负数: '高度不能為負數',
    横向数量: '橫向數量',
    '最大值100,最小值1': '最大值100,最小值1',
    横向间距: '橫向間距',
    '横向间距.tips1': '最大值500mm,最小值-500mm',
    纵向数量: '縱向數量',
    纵向间距: '縱向間距',
    '最大值500mm,最小值-500mm': '最大值500mm,最小值-500mm',
    方向: '方向',
    缺省出血: '缺省出血',
    '最大值10mm,最小值0mm': '最大值10mm,最小值0mm',
    单元间距: '單元間距',
    补偿曲线: '補償曲線',
    RIP分辨率: 'RIP解析度',
    RIP网点形状: 'RIP網點形狀',
    加网线数: '加網線數',
    '最大值400,最小值50': '最大值400,最小值50',
    最小网点尺寸: '最小網點尺寸',
    '最大值200,最小值5': '最大值200,最小值5',
    过度点: '過度點',
    '最大值20,最小值0': '最大值20,最小值0',
    网点角度: '網點角度',
    水平变形率: '水準變形率',
    '最大值110,最小值90': '最大值110,最小值90',
    垂直变形率: '垂直變形率',
    是否推送审批链接至邮箱: '是否推送審批連結至郵箱',
    邮箱: '郵箱',
    请输入正确的邮箱: '請輸入正確的郵箱',
    是否推送链接到我的微信: '是否推送連結到我的微信',
    选择微穴种子文件: '選擇微穴種子檔',
    网墙宽度: '網牆寬度',
    '最大值3mm,最小值0mm': '最大值3mm,最小值0mm',
    最小网点: '最小網點',
    是否使用网墙微穴: '是否使用網牆微穴',
    网墙微穴种子文件: '網牆微穴種子文件',
    是否使用切边微穴: '是否使用切邊微穴',
    切边微穴种子文件: '切邊微穴種子文件',
    切边高度: '切邊高度',
    '最大值3mm,最小值0.01mm': '最大值3mm,最小值0.01mm',
    是否区分实地和非实地: '是否區分實地和非實地',
    非实地微穴加网: '非實地微穴加網',
    数量: '數量',
    '最大值10000,最小值0': '最大值10000,最小值0',
    已处理文件归集: '已處理文件歸集',
    '将已处理的原件文件在"原件"文件夹删除，归集到"混拼-混拼原件"文件夹，混拼结果文件放入"混拼-结果文件"文件夹。':
        '將已處理的原件檔在"原件"資料夾刪除，歸集到"混拼-混拼原件"資料夾，混拼結果檔放入"混拼-結果檔"資料夾。',
    模型: '模型',
    名称: '名稱',
    输出模式: '輸出模式',
    每个文件页数: '每個檔頁數',
    从哪行数据开始: '從哪行資料開始',
    最小值1: '最小值1',
    执行数据行数: '執行資料行數',
    '0表示全部': '0表示全部',
    结束必须大于起始: '結束必須大於起始',
    版号: '版號',
    '单摸-背旋转方向': '單摸-背旋轉方向',
    '单摸-正旋转方向': '單摸-正旋轉方向',
    版长: '版長',
    周长: '周長',
    左走空: '左走空',
    左跟踪宽度: '左跟蹤寬度',
    '左出血-背': '左出血-背',
    '右出血-背': '右出血-背',
    '左出血-底': '左出血-底',
    '右出血-底': '右出血-底',
    '左出血-正': '左出血-正',
    '右出血-正': '右出血-正',
    上出血: '上出血',
    下出血: '下出血',
    右走空: '右走空',
    右跟踪宽度: '右跟蹤寬度',
    是否制袋: '是否制袋',
    数量要求: '數量要求',
    规格尺寸: '規格尺寸',
    袋型: '袋型',
    开口方向: '開口方向',
    封边宽度: '封邊寬度',
    撕口位置: '撕口位置',
    圆角: '圓角',
    双切刀: '雙切刀',
    吊挂孔: '吊掛孔',
    孔位置: '孔位置',
    烫刀形状: '燙刀形狀',
    拉链类型: '拉鍊類型',
    模切要求: '模切要求',
    拉链位置: '拉鍊位置',
    尺寸确认: '尺寸確認',
    封口牢度: '封口牢度',
    漏气: '漏氣',
    图文制作: '圖文製作',
    印刷要求: '印刷要求',
    细节确认: '細節確認',
    制袋操作: '制袋操作',
    完成时间: '完成時間',
    是否制作: '是否製作',
    图稿标准: '圖稿標準',
    稿件确认方式: '稿件確認方式',
    色样标准: '色樣標準',
    色样确认方式: '色樣確認方式',
    拼数: '拼數',
    单元规格: '單元規格',
    其他要求: '其他要求',
    稿件名称: '稿件名稱',
    印前操作: '印前操作',
    是否印刷: '是否印刷',
    自带料: '自帶料',
    自带料位置: '自帶料位置',
    '材料/规格': '材料/規格',
    有效印宽: '有效印寬',
    出卷方向: '出卷方向',
    印刷数量: '印刷數量',
    头出: '頭出',
    '头出 90度': '頭出 90度',
    尾出: '尾出',
    '尾出 90度': '尾出 90度',
    无要求: '無要求',
    文件下载: '文件下載',
    访问密码: '訪問密碼',
    有效期: '有效期',
    支持: '支持',
    创建分享链接: '創建分享連結',
    '请输入访问密码，非必填': '請輸入訪問密碼，非必填',
    创建成功: '創建成功',
    分享链接创建成功: '分享連結創建成功',
    复制成功: '複製成功',
    保存参数: '保存參數',
    将当前参数保存为参数文件: '將當前參數保存為參數檔',
    文件保存位置: '檔保存位置',
    资源: '資源',
    功能参数: '功能參數',
    文件名称: '檔案名稱',
    输入文件名称: '輸入檔案名稱',
    参数文件: '參數文件',
    选择参数文件: '選擇參數檔',
    请输入要搜索的文件: '請輸入要搜索的檔',
    更多设置: '更多設置',
    文件尺寸不能大于: '檔尺寸不能大於',
    最多只能选择15个文件进行处理: '最多只能選擇15個檔進行處理',
    项目设置: '專案設置',
    混拼: '混拼',
    标记文件变量值自定义: '標記檔變數值自訂',
    自定义值: '自訂值',
    变量自定义: '變數自訂',
    '当前文件上传中，暂时无法删除': '當前檔上傳中，暫時無法刪除',
    '最大值10160,最小值72': '最大值10160,最小值72',
    精度: '精度',
    点击上传: '點擊上傳',
    不支持该类型文件查看: '不支援該類型檔查看',
    自定义网点角度: '自訂網點角度',
    新增网点角度: '新增網點角度',
    度: '度',
    '其他专色默认为45°': '其他專色默認為45°',
    上传中: '上傳中',
    检索半色调: '檢索半色調',
    文件数量不能大于: '檔數量不能大於',
    文件数量必须大于: '檔數量必須大於',
    描述文本: '描述文本',
    选择方案配置: '選擇方案配置',
    文件初始化中请稍后: '文件初始化中請稍後',
    拼版模式: '拼版模式',
    传统: '傳統',
    数码: '數碼',
    请选择标记: '請選擇標記',
    请选择微穴种子文件: '請選擇微穴種子文件',
    请选择网墙微穴种子文件: '請選擇網墻微穴種子文件',
    请选择切边微穴种子文件: '請選擇切邊微穴種子文件',
    出血距离: '出血距離',
    '标签文件出血条件：文件中的刀线油墨需命名：die、diecut、cut、刀模':
        '標簽文件出血條件：文件中的刀線油墨需命名：die、diecut、cut、刀模',
    页面范围: '頁面範圍',
    全部: '',
    第一页: '第壹頁',
    选定范围: '選定範圍',
    格式: '格式',
    高分辨率图像: '高分辨率圖像',
    最大值: '最大值',
    最小值: '最小值',
    检查蒙版: '檢查蒙版',
    降精度: '降精度',
    请选择: '請選擇',
    正常: '正常',
    警告: '警告',
    错误: '錯誤',
    高分辨率位图: '高分辨率位圖',
    低分辨率图像: '低分辨率圖像',
    低分辨率位图: '低分辨率位圖',
    检测条形码: '檢測條形碼',
    识别: '識別',
    混合油墨条形码: '混合油墨條形碼',
    非嵌入字体: '非嵌入字體',
    缺少字体符号: '缺少字體符號',
    子集字体: '子集字體',
    混合油墨文本: '混合油墨文本',
    文本对象: '文本對象',
    矢量化: '矢量化',
    字体大小: '字體大小',
    最少: '最少',
    等于: '等于',
    最多: '最多',
    至少包含分色数量: '至少包含分色數量',
    分析文本对象: '分析文本對象',
    RGB对象: 'RGB對象',
    分析图像对象: '分析圖像對象',
    分析其他对象: '分析其他對象',
    转换为CMYK: '轉換爲CMYK',
    lab对象: 'lab對象',
    灰色对象: '灰色對象',
    仅在CMYK图像中: '僅在CMYK圖像中',
    仅在图像中: '僅在圖像中',
    在所有对象中: '在所有對象中',
    移除: '移除',
    镂空黑色物体: '镂空黑色物體',
    进行叠印: '進行疊印',
    镂空黑色文本: '镂空黑色文本',
    最大宽度: '最大寬度',
    镂空黑色边框: '镂空黑色邊框',
    镂空黑色矩形: '镂空黑色矩形',
    分析填充: '分析填充',
    分析边框: '分析邊框',
    镂空的技术油墨: '镂空的技術油墨',
    镂空的光油墨: '镂空的光油墨',
    镂空的不透明油墨: '镂空的不透明油墨',
    叠印的白色物体: '疊印的白色物體',
    移除叠印: '移除疊印',
    混合油墨对象: '混合油墨對象',
    '技术/非技术油墨混合': '技術/非技術油墨混合',
    未使用的分色: '未使用的分色',
    去除: '去除',
    颜色: '顔色',
    至: '至',
    加网物件: '加網物件',
    移除加网: '移除加網',
    XMP加网信息: 'XMP加網信息',
    设置为默认值: '設置爲默認值',
    未加网对象: '未加網對象',
    查找未加网的对象: '查找未加網的對象',
    查找缺少分色项的加网: '查找缺少分色項的加網',
    查找缺少分色的未加网对象: '查找缺少分色的未加網對象',
    边框: '邊框',
    分色计数: '分色計數',
    笔画宽度: '筆畫寬度',
    修复: '修複',
    开放路径: '開放路徑',
    关闭: '關閉',
    建议的替换文案: '建議的替換文案',
    替换: '替换',
    印版宽度: '印版寬度',
    印版高度: '印版高度',
    纸张宽度: '紙張寬度',
    纸张高度: '紙張高度',
    纸张参考点: '紙張參考點',
    上左: '上左',
    上中: '上中',
    上右: '上右',
    左: '左',
    中心: '中心',
    右: '右',
    下左: '下左',
    下中: '下中',
    下右: '下右',
    纸张水平偏移: '紙張水平偏移',
    纸张垂直偏移: '紙張垂直偏移',
    拼版参考点: '拼版參考點',
    拼版水平偏移: '拼版水平偏移',
    拼版垂直偏移: '拼版垂直偏移',
    左间距: '左間距',
    右间距: '右間距',
    上间距: '上間距',
    下间距: '下間距',
    单模文件页码: '單模文件頁碼',
    裁切路径: '裁切路徑',
    媒体框: '媒體框',
    裁剪框: '裁剪框',
    修剪框: '修剪框',
    出血框: '出血框',
    编辑框: '編輯框',
    根据单模设置: '根據單模設置',
    重复方向: '重複方向',
    上: '上',
    下: '下',
    镜像: '鏡像',
    左右: '左右',
    上下: '上下',
    水平拼数: '水平拼數',
    垂直拼数: '垂直拼數',
    水平偏移: '水平偏移',
    垂直偏移: '垂直偏移',
    水平间隙: '水平間隙',
    垂直间隙: '垂直間隙',
    交错方向: '交錯方向',
    交错偏移距离: '交錯偏移距離',
    交错偏移比例: '交錯偏移比例',
    重新排列: '重新排列',
    列: '列',
    将额外的台版添加到: '將額外的台版添加到',
    奇数: '奇數',
    偶数: '偶數',
    包括部分一键启动: '包括部分壹鍵啓動',
    交错转向: '交錯轉向',
    头对头: '頭對頭',
    头对尾: '頭對尾',
    尾对尾: '尾對尾',
    尾对头: '尾對頭',
    交错行间距类型: '交錯行間距類型',
    行的间隙: '行的間隙',
    列的间隙: '列的間隙',
    交错行间距个数: '交錯行間距個數',
    交错行间距长度: '交錯行間距長度',
    生成单模号: '生成單模號',
    单模编号前缀: '單模編號前綴',
    单模编号样式: '單模編號樣式',
    数字: '數字',
    罗马数字: '羅馬數字',
    单模编号起始位置: '單模編號起始位置',
    左上: '左上',
    右上: '右上',
    右下: '右下',
    左下: '左下',
    单模编号排列方向: '單模編號排列方向',
    横向: '横向',
    横向蛇形: '横向蛇形',
    竖向: '豎向',
    竖向蛇形: '豎向蛇形',
    出血: '出血',
    获取单模的出血距离: '獲取單模的出血距離',
    水平无缝连接: '水平無縫連接',
    垂直无缝连接: '垂直無縫連接',
    从第一个区块开始应用变量: '從第壹個區塊開始應用變量',
    到黑色: '到黑色',
    到斑点: '到斑點',
    到不透明: '到不透明',
    重叠: '重疊',
    反向陷印: '反向陷印',
    强度: '强度',
    回拉: '回拉',
    允许所有: '允許所有',
    压平部分: '壓平部分',
    黑色压平部分: '黑色壓平部分',
    无: '無',
    回拉类型: '回拉類型',
    两者: '兩者',
    单个: '單個',
    只剩一个色: '只剩壹個色',
    回拉来源: '回拉來源',
    所有: '所有',
    仅限白色: '僅限白色',
    回拉距离: '回拉距離',
    边角: '邊角',
    截断: '截斷',
    允许对象相关陷印: '允許對象相關陷印',
    保持对象形状: '保持對象形狀',
    斜角: '斜角',
    尖角: '尖角',
    直角: '直角',
    中心截断: '中心截斷',
    边缘截断: '邊緣截斷'
}
