export default {
    节点参数: '節點參數',
    流程终止: '流程終止',
    有错误: '有錯誤',
    有错误与警告: '有錯誤與警告',
    不限制: '不限制',
    所有审批审核失败: '所有審批審核失敗',
    结果文件: '結果文件',
    报告文件: '報告文件',
    流程: '流程',
    全部: '全部',
    印前: '印前',
    流程图像: '流程圖像',
    流程名称: '流程名稱',
    流程简介: '流程簡介',
    流程配置: '流程配置'
}
