export default {
    'menu.welcome': '欢迎',
    'menu.home': '首页',
    'menu.fileview': '预览',
    'menu.login': '登录',
    'menu.account': '个人中心',
    'menu.account.login': '登录',
    'menu.account.center': '个人中心',
    'menu.account.settings': '个人信息',
    'menu.account.trigger': '触发报错',
    'menu.account.logout': '退出登录',
    'menu.account.resources': '个人资源',
    'menu.user': '用户管理',
    'menu.user.list': '用户列表',
    'menu.user.role': '角色权限',
    'menu.user.menu': '菜单管理',
    'menu.user.login': '登录',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.exception.not-permission': '403',
    'menu.exception.not-find': '404',
    'menu.exception.server-error': '500',
    'menu.exception.trigger': '触发错误',
    'menu.file-explorer': '文件处理',
    'menu.file-explorer.list': '我的素材',
    'menu.program': '印前项目',
    'menu.program.list': '我的项目',
    'menu.program.item': '项目信息',
    'menu.jobs': '作业管理',
    'menu.jobs.list': '作业列表',
    'menu.customer': '客户管理',
    'menu.customer.list': '客户信息',
    'menu.customer.contact': '联系人',
    'menu.customer.contact_item': '联系人详情',
    'menu.customer.item': '客户详情',
    'menu.account.company': '我的企业',

    印路科技: '印路科技',

    '印丫云-让印前更简单': '印丫云-让印前更简单',

    关闭: '关闭',

    印路印前: '印路印前',

    使用教程: '使用教程',
    激活成功: '激活成功',

    激活: '激活',
    '抱歉, 没有访问权限。': '抱歉, 没有访问权限。',
    返回首页: '返回首页',
    '页面已更新，请刷新页面': '页面已更新，请刷新页面',
    '抱歉, 发生异常错误，请稍后再试。': '抱歉, 发生异常错误，请稍后再试。',
    发生错误: '发生错误',
    '发生错误！': '发生错误！',
    编号: '编号',
    企业编号: '企业编号',

    部门: '部门',
    部门类别: '部门类别',
    职务: '职务',
    籍贯: '籍贯',
    兴趣爱好: '兴趣爱好',
    生日: '生日',
    年龄: '年龄',
    年龄区间: '年龄区间',
    教育程度: '教育程度',
    学校: '学校',
    备注: '备注',

    个人邮件: '个人邮件',
    联系方式: '联系方式',
    联系人关系: '联系人关系',
    岗位信息: '岗位信息',
    用户编号: '用户编号',

    启用: '启用',

    客户地址: '客户地址',
    客户传真: '客户传真',
    客户网址: '客户网址',
    客户邮编: '客户邮编',
    经营信息: '经营信息',
    客户关系: '客户关系',
    成交信息: '成交信息',
    负责人: '负责人',

    新建用户: '新建用户',
    编辑用户: '编辑用户',
    '用户名（姓名）手机号 邮箱 模糊检索': '用户名（姓名）手机号 邮箱 模糊检索',
    账号: '账号',

    再次录入密码: '再次录入密码',

    电子邮箱: '电子邮箱',
    角色权限: '角色权限',
    角色名称: '角色名称',
    新建角色: '新建角色',
    编辑角色: '编辑角色',
    上一级编号: '上一级编号',
    类型: '类型',
    菜单名称: '菜单名称',
    菜单翻译key值: '菜单翻译key值',
    菜单图标: '菜单图标',
    排序: '排序',
    权限字符: '权限字符',
    路由路径: '路由路径',
    组件路径: '组件路径',
    '属性关键词模糊搜索，支持回车搜索。': '属性关键词模糊搜索，支持回车搜索。',
    新建客户: '新建客户',

    '绑定成功！': '绑定成功！',

    '请输入8-15位密码': '请输入8-15位密码',

    手机号错误: '手机号错误',

    请输入新邮箱: '请输入新邮箱',

    您身边的印前专家: '您身边的印前专家',

    忘记密码: '忘记密码',

    关注印路科技公众号进行注册: '关注印路科技公众号进行注册',

    注册并登录: '注册并登录',

    账户设置: '账户设置',

    文件云盘: '文件云盘',

    换绑邮箱: '换绑邮箱',
    设置密码: '设置密码',

    '其他账户已经绑定该微信！': '其他账户已经绑定该微信！',

    绑定后即可用该邮箱号登录账号: '绑定后即可用该邮箱号登录账号',
    邮箱错误: '邮箱错误',
    '换绑后将无法使用该邮箱号登录此账号！': '换绑后将无法使用该邮箱号登录此账号！',
    现在邮箱: '现在邮箱',
    绑定后即可用该手机号登录账号: '绑定后即可用该手机号登录账号',

    现在手机号: '现在手机号',
    手机号找回: '手机号找回',
    邮箱号找回: '邮箱号找回',

    请绑定邮箱: '请绑定邮箱',
    '请选择.cfcurve文件': '请选择.cfcurve文件',

    请选择pdf文件: '请选择pdf文件',
    '请选择.tif文件': '请选择.tif文件',

    添加印前工单项目: '添加印前工单项目',
    信息管理: '信息管理',
    '正在生成，稍后请在【历史记录】中查看': '正在生成，稍后请在【历史记录】中查看',

    项目文件: '项目文件',

    是否确认删除: '是否确认删除',
    '个文件？': '个文件？',

    请选择至少一项: '请选择至少一项',

    当前处于离线状态: '当前处于离线状态',
    有新内容: '有新内容',
    '请点击“刷新”按钮或者手动刷新页面': '请点击“刷新”按钮或者手动刷新页面'
}
