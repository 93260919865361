export default {
    到期时间: '到期時間',
    您还未加入企业: '您還未加入企業',
    人员信息: '人員資訊',
    仅看自己印前项目: '僅看自己印前項目',
    所有成员印前项目: '所有成員印前項目',
    我的客服: '我的客服',
    工作日: '工作日',
    印前服务: '印前服務',
    本年已服务次数: '本年已服務次數',
    服务内容包含: '服務內容包含',
    '1、精准的标记文件制作：包括切割线、折叠线和颜色校验条等，确保印刷过程中的准确性和效率。':
        '1、精准的標記檔製作：包括切割線、折疊線和顏色校驗條等，確保印刷過程中的準確性和效率。',
    '2、高质量微穴种子制作：制作精细的屏幕点图案，以优化印刷品的质感和色彩深度。':
        '2、高品質微穴種子製作：製作精細的螢幕點圖案，以優化印刷品的質感和色彩深度。',
    '3、补偿曲线定制：会员定制补偿曲线，解决由纸张、油墨等因素引起的偏差，确保印刷输出忠实于原始设计。':
        '3、補償曲線定制：會員定制補償曲線，解決由紙張、油墨等因素引起的偏差，確保印刷輸出忠實於原始設計。',
    '4、VDP可变数据文件制作：可变数据打印（VDP）文件制作服务，将会员客户的个性化数据集成到印刷物中。':
        '4、VDP可變資料檔案製作：可變資料列印（VDP）檔製作服務，將會員客戶的個性化資料集成到印刷物中。'
}
