// 本地缓冲key

//token
export const TOKEN_KEY = 'token'
export const SHARED_TOKEN_KEY = 'shared_token'
//账号
export const ACCOUNT_KEY = 'account'
//设置
export const SETTING_KEY = 'setting'

// 配置版本
export const CONFIG_VERSION_KEY = 'config_version'
export const CONFIG_DATA_KEY = 'config_data_'
