export default {
    绑定邮箱: '绑定邮箱',
    请输入邮箱: '请输入邮箱',
    请输入正确的邮箱: '请输入正确的邮箱',
    请绑定手机号: '请绑定手机号',
    绑定微信: '绑定微信',
    绑定后即可用该微信扫码登录账号: '绑定后即可用该微信扫码登录账号',
    修改邮箱: '修改邮箱',
    当前邮箱: '当前邮箱',
    换绑手机号: '换绑手机号',
    '换绑后将无法使用该手机号登录此账号！': '换绑后将无法使用该手机号登录此账号！',
    当前手机号: '当前手机号',
    文件云盘已用: '文件云盘已用',
    共: '共',
    会员权限: '会员权限',
    账号剩余: '账号剩余',
    续费: '续费',
    本周上传文件数: '本周上传文件数',
    请输入激活码: '请输入激活码',
    联系客户经理购买激活码: '联系客户经理购买激活码',
    请输入正确的激活码: '请输入正确的激活码',
    手机: '手机',
    修改手机: '修改手机',
    绑定手机: '绑定手机',
    邮箱: '邮箱',
    密码: '密码',
    重置密码: '重置密码',
    '是否解除绑定？': '是否解除绑定？',
    密码设置: '密码设置',
    请先绑定手机或者邮箱: '请先绑定手机或者邮箱',
    手机号: '手机号',
    设置成功: '设置成功',
    请选择图片上传: '请选择图片上传',
    个人账号剩余: '个人账号剩余'
}
