export default {
    请选择: '請選擇',
    文件: '文件',
    文件不能大于50M: '檔不能大於50M',
    标记: '標記',
    '仅支持".pdf"格式文件': '僅支持".pdf"格式檔',
    微穴种子: '微穴種子',
    '仅支持".tif"格式文件': '僅支持".tif"格式檔',
    补偿曲线: '補償曲線',
    '仅支持".cfcurve"格式文件': '僅支持".cfcurve"格式檔',
    '仅支持".json"格式文件': '僅支持".json"格式檔',
    刀模: '刀模',
    '仅支持".cf2"格式文件': '僅支持".cf2"格式檔',
    mark: '標記',
    seed: '微穴種子',
    dgc: '補償曲線',
    arg: '功能參數',
    daomu: '刀模',
    'resources.10201.name': '標記',
    'resources.10202.name': '微穴種子',
    'resources.10203.name': '補償曲線',
    'resources.10206.name': '刀模',
    'resources.10205.name': '功能參數',
    'resources.1020501.name': '預檢',
    'resources.1020502.name': '陷印',
    'resources.1020503.name': '對比',
    'resources.1020504.name': '拼版',
    'resources.1020505.name': 'RIP',
    'resources.1020506.name': 'LenToTIFF',
    'resources.1020507.name': '審批',
    'resources.1020508.name': '智能柔印',
    'resources.1020509.name': '混拼',
    'resources.1020510.name': '畫質增強',
    'resources.1020511.name': '3D光線追蹤',
    'resources.1020512.name': '色彩轉換',
    'resources.1020513.name': 'VDP',
    'resources.1020514.name': '軟包訂單拼版',
    'resources.1020517.name': '刀模拼版',
    'resources.1020518.name': 'Tiff精度轉換'
}
