export default {
    'menu.welcome': 'Welcome',
    'menu.home': 'Home',
    'menu.fileview': 'Preview',
    'menu.login': 'Login',
    'menu.account': 'Profile',
    'menu.account.login': 'Log in',
    'menu.account.center': 'Personal Center',
    'menu.account.settings': 'Personal Settings',
    'menu.account.trigger': 'Triggered an Error',
    'menu.account.logout': 'Logout',
    'menu.account.resources': 'Personal Resources',
    'menu.user': 'User Management',
    'menu.user.list': 'User List',
    'menu.user.role': 'Role Permissions',
    'menu.user.menu': 'Menu Management',
    'menu.user.login': 'Login',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.exception.not-permission': '403',
    'menu.exception.not-find': '404',
    'menu.exception.server-error': '500',
    'menu.exception.trigger': 'Triggered an Error',
    'menu.file-explorer': 'File Management',
    'menu.file-explorer.list': 'File List',
    'menu.program': 'Prepress Projects',
    'menu.program.list': 'My Projects',
    'menu.program.item': 'Project Information',
    'menu.jobs': 'Job Management',
    'menu.jobs.list': 'Job List',
    'menu.customer': 'Customer Management',
    'menu.customer.list': 'Customer List',
    'menu.customer.contact': 'contact',
    'menu.customer.contact_item': 'item',
    'menu.customer.item': 'item',

    印路科技: 'printroad',

    '印丫云-让印前更简单': 'printroad-Your prepress management expert',

    关闭: 'Close',

    印路印前: 'printroad',

    使用教程: 'Usage Tutorial',
    激活成功: 'Activation Successful',

    激活: 'Activate',
    '抱歉, 没有访问权限。': 'Sorry, no access permission',
    返回首页: 'Home page',
    '页面已更新，请刷新页面': 'The page has been updated, please refresh the page',
    '抱歉, 发生异常错误，请稍后再试。': 'Sorry, an error occurred, please try again later',
    发生错误: 'Error occurred',
    '发生错误！': 'Error occurred!',
    编号: 'contact_id',
    企业编号: 'Enterprise number',

    部门: 'department',
    部门类别: 'department_type',
    职务: 'job tittle',
    籍贯: 'native_place',
    兴趣爱好: 'hobbies',
    生日: 'birthday',
    年龄: 'age',
    年龄区间: 'age_range',
    教育程度: 'education_level',
    学校: 'school',
    备注: 'remark',

    个人邮件: 'p_email',
    联系方式: 'contact_info',
    联系人关系: 'contact_relationship',
    岗位信息: 'job_info',
    用户编号: 'uid',

    启用: 'enabled',

    客户地址: 'address',
    客户传真: 'fax',
    客户网址: 'website',
    客户邮编: 'postcode',
    经营信息: 'business_info',
    客户关系: 'customer_relations',
    成交信息: 'transaction_info',
    负责人: 'principal',

    新建用户: 'add',
    编辑用户: 'edit',
    '用户名（姓名）手机号 邮箱 模糊检索': 'Attribute Keyword Fuzzy Search, Supports Enter to Search',
    账号: 'username',

    再次录入密码: 'Re-enter password',

    电子邮箱: 'email',
    角色权限: 'role',
    角色名称: 'name',
    新建角色: 'add',
    编辑角色: 'edit',
    上一级编号: 'pid',
    类型: 'type',
    菜单名称: 'remark',
    菜单翻译key值: 'name',
    菜单图标: 'icon',
    排序: 'sort',
    权限字符: 'perms',
    路由路径: 'paths',
    组件路径: 'component',
    '属性关键词模糊搜索，支持回车搜索。': 'Attribute Keyword Fuzzy Search, Supports Enter to Search',
    新建客户: 'add',

    '绑定成功！': 'Bind successfully!',

    '请输入8-15位密码': 'Please enter a password with 8-15 characters',

    手机号错误: 'Mobile number error',

    您身边的印前专家: 'Your prepress management expert',

    关注印路科技公众号进行注册: 'Subscribe to the Yinluo Technology official account for registration',

    注册并登录: 'Register and log in',

    账户设置: 'Account settings',

    文件云盘: 'Cloud Drive',

    换绑邮箱: 'Change email address',
    设置密码: 'Set password',

    '其他账户已经绑定该微信！': 'Other accounts have already bound this WeChat!',

    绑定后即可用该邮箱号登录账号: 'You can log in with this email after binding',
    邮箱错误: 'Email error',
    '换绑后将无法使用该邮箱号登录此账号！': "but you can't log in with this email after changing! ",
    现在邮箱: 'Current email address',
    绑定后即可用该手机号登录账号: 'Bind now and log in with this mobile phone number after binding',

    现在手机号: 'Current mobile phone number',
    手机号找回: 'Mobile phone number retrieval',
    邮箱号找回: 'Email address retrieval',

    请绑定邮箱: 'Please bind your email address',
    '请选择.cfcurve文件': 'Please select .cfcurve file',

    请选择pdf文件: 'Please select PDF file',
    '请选择.tif文件': 'Please select .tif file',

    添加印前工单项目: 'Add prepress work order project',
    信息管理: 'Information management',
    '正在生成，稍后请在【历史记录】中查看': 'Generating, please check in the "History" later',

    项目文件: 'Project file',
    '文件尺寸过大，无法上传': 'File size is too large to upload',
    上传失败: 'Upload failed',
    上传原件: 'Upload original file',
    是否确认删除: 'Confirm deletion of file?',
    '个文件？': 'files？',

    请选择至少一项: 'Please select at least one item',

    当前处于离线状态: 'You are offline now',
    有新内容: 'New content available',
    '请点击“刷新”按钮或者手动刷新页面': 'Please press the "Refresh" button to reload current page'
}
