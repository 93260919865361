export default {
    绑定邮箱: '綁定郵箱',
    请输入邮箱: '請輸入郵箱',
    请输入正确的邮箱: '請輸入正確的郵箱',
    请绑定手机号: '請綁定手機號',
    绑定微信: '綁定微信',
    绑定后即可用该微信扫码登录账号: '綁定後即可用該微信掃碼登錄帳號',
    修改邮箱: '修改郵箱',
    当前邮箱: '當前郵箱',
    换绑手机号: '換綁手機號',
    '换绑后将无法使用该手机号登录此账号！': '換綁後將無法使用該手機號登錄此帳號！',
    当前手机号: '當前手機號',
    文件云盘已用: '文件雲盤已用',
    共: '共',
    会员权限: '會員許可權',
    账号剩余: '帳號剩餘',
    续费: '續費',
    本周上传文件数: '本周上傳文件數',
    请输入激活码: '請輸入啟動碼',
    联系客户经理购买激活码: '聯繫客戶經理購買啟動碼',
    请输入正确的激活码: '請輸入正確的啟動碼',
    手机: '手機',
    修改手机: '修改手機',
    绑定手机: '綁定手機',
    邮箱: '郵箱',
    密码: '密碼',
    重置密码: '重置密碼',
    '是否解除绑定？': '是否解除綁定？',
    密码设置: '密碼設置',
    请先绑定手机或者邮箱: '請先綁定手機或者郵箱',
    手机号: '手機號',
    设置成功: '設置成功',
    请选择图片上传: '請選擇圖片上傳',
    个人账号剩余: '個人帳號剩餘'
}
