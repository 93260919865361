import { throttle } from 'lodash-es'
import { message } from 'ant-design-vue'
import cache from '../cache'
import { TOKEN_KEY } from '@/enums/cacheEnums'
import i18n from '@/lang'
export const throttleLoginxpiration = throttle(() => {
    message.warning(i18n.global.t('登录已过期，请重新登录'))
    cache.remove(TOKEN_KEY)
    setTimeout(() => {
        window.location.href = '/login'
    }, 1000)
}, 1800)
