/**
 * 这个是解决在vue组件中动态传入url,标签不会加载资源的问题
 * @param url
 * @returns
 */
export const require = (url: string) => new URL(url.replace('@', '..'), import.meta.url)?.href ?? null

// 手机号隐藏展示
export const maskPhoneNumber = value => {
    if (value && /^\d{11}$/.test(value)) {
        return value.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    }
    return value
}

// 邮箱隐藏展示
export const maskEmailAddress = value => {
    if (value && /(.{3}).*(@.*)/.test(value)) {
        return value.replace(/(.{3}).*(@.*)/, '$1****$2')
    }
    return value
}

// 获取文件后缀名
export function getExtension(name: string) {
    return name ? name.substring(name.lastIndexOf('.')) : ''
}

// const json = {
//     name: '张三',
//     addr: '广州'
// }
// const str = JSON.stringify(json, null, 2)
// const url = `data:,${str}`
// download(url, 'a.json')
export function download(url: string, filename = '') {
    // window.open(url);

    let a = document.createElement('a')
    a.style.display = 'none'
    if (filename != '') {
        a.download = filename
        // a.setAttribute('download', decodeURI(filename));
    }
    a.href = url
    document.body.appendChild(a)
    a.click() // 触发a标签的click事件
    document.body.removeChild(a)
}

// 获取地址栏参数
export const getQueryString = name => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = window.location.search.substring(1).match(reg)
    if (r != null) return decodeURIComponent(r[2])
    return null
}

// 根据url获取图片的宽高
export function getImageSize(url) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url

        img.onload = function () {
            resolve({ width: img?.width ?? 0, height: img?.height ?? 0 })
        }

        img.onerror = function () {
            reject(new Error('Failed to load image'))
        }
    })
}

// 判断是否是移动端
export function isMobile() {
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    return !(navigator.userAgent.match(mobileRegex) == null)
}

// 判断数组包含状态
export const isContained = (totalList: any[], sectionList: any[]): number => {
    const total = totalList.concat(sectionList)
    const setTotal = new Set(total)
    const repeat = total.length - setTotal.size
    if (repeat === 0) return 0 // totalList里不包含sectionList的任何项
    if (repeat < sectionList.length) return 1 // totalList里包含sectionList的项,不包含全部
    if (repeat === sectionList.length) return 2 // totalList里包含sectionList的全部项
    return -1 // 错误
}
