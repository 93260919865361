export default {
    客户编号: '客戶編號',
    客户名称: '客戶名稱',
    客户简称: '客戶簡稱',
    客户类别: '客戶類別',
    客户分类: '客戶分類',
    客户状态: '客戶狀態',
    联系电话: '聯繫電話',
    联系邮箱: '聯繫郵箱',
    请输入客户名称: '請輸入客戶名稱',
    所属客户: '所屬客戶',
    请输入姓名: '請輸入姓名',
    请输入所属客户: '請輸入所屬客戶'
}
