export default {
    项目: '項目',
    客户: '客戶',
    企业: '企業',
    资源: '資源',
    语言: '語言',
    个人: '個人',
    个人信息: '個人資訊',
    退出登录: '退出登錄',
    客户信息: '客戶資訊',
    联系人: '連絡人',
    全屏展示: '全屏展示',
    取消全屏: '取消全屏',
    最新版本: '最新版本',
    项目栏目: '項目欄目'
}
