export default {
    客户编号: '客户编号',
    客户名称: '客户名称',
    客户简称: '客户简称',
    客户类别: '客户类别',
    客户分类: '客户分类',
    客户状态: '客户状态',
    联系电话: '联系电话',
    联系邮箱: '联系邮箱',
    请输入客户名称: '请输入客户名称',
    所属客户: '所属客户',
    请输入姓名: '请输入姓名',
    请输入所属客户: '请输入所属客户'
}
