import { createRouter, createWebHistory } from 'vue-router'
import { TOKEN_KEY } from '@/enums/cacheEnums'

const NotFound = () => import('@/views/404.vue')
const Layout = () => import('@/layout/index.vue')
const Login = () => import('@/views/login/index.vue')
const shared = () => import('@/views/shared/index.vue')
const Home = () => import('@/views/project/index.vue')
const User = () => import('@/views/user/list/index.vue')
const Contact = () => import('@/views/user/contact/index.vue')
const Company = () => import('@/views/company/index.vue')
const Account = () => import('@/views/account/index.vue')
const Resources = () => import('@/views/resources/index.vue')
const FormConfigList = () => import('@/views/formConfig/list/index.vue')
const FormConfigDetail = () => import('@/views/formConfig/detail/index.vue')
const FormConfigSelectSkill = () => import('@/views/formConfig/selectSkill/index.vue')
const FlowList = () => import('@/views/flow/list/index.vue')
const FlowDetail = () => import('@/views/flow/detail/index.vue')
const FlowConfig = () => import('@/views/flow/config/index.vue')
const FlowHistory = () => import('@/views/flow/history/index.vue')
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/shared',
            name: 'shared',
            component: shared
        },
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '/',
                    redirect: '/home'
                },
                {
                    path: '/home',
                    name: 'home',
                    component: Home,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/user',
                    children: [
                        {
                            path: 'list',
                            name: 'user',
                            component: User,
                            meta: {
                                requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                            }
                        },
                        {
                            path: 'contact',
                            name: 'contact',
                            component: Contact,
                            meta: {
                                requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                            }
                        }
                    ]
                },
                {
                    path: '/company',
                    name: 'company',
                    component: Company,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: Account,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/resources',
                    name: 'resources',
                    component: Resources,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/formConfigList',
                    name: 'formConfigList',
                    component: FormConfigList,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/formConfigDetail',
                    name: 'formConfigDetail',
                    component: FormConfigDetail,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/formConfigSelectSkill',
                    name: 'formConfigSelectSkill',
                    component: FormConfigSelectSkill,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/flow',
                    name: 'flow',
                    component: FlowList,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/flowDetail',
                    name: 'flowDetail',
                    component: FlowDetail,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/flowConfig',
                    name: 'flowConfig',
                    component: FlowConfig,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                },
                {
                    path: '/flowHistory',
                    name: 'flowHistory',
                    component: FlowHistory,
                    meta: {
                        requireAuth: true // 添加一个 meta 字段，表示该路由需要验证登录状态
                    }
                }
                // 其他页面路由配置
            ]
        },
        // 404 页面路由，放在最后
        {
            path: '/:pathMatch(.*)*', // 匹配所有路径
            name: '404',
            component: NotFound // 渲染自定义的 404 页面组件
        }
    ]
})

// 导航守卫
router.beforeEach((to, _, next) => {
    const isLoggedIn = Boolean(localStorage.getItem(TOKEN_KEY)) // 判断用户是否已登录
    if (to.meta.requireAuth && !isLoggedIn) {
        // 需要验证登录状态，并且未登录
        next('/login') // 跳转到登录页
    } else if (to.path === '/login') {
        if (isLoggedIn) {
            // 如果用户已登录并访问登录页面，则自动跳转到首页
            next('/home')
        } else {
            window.localStorage.clear()
            next()
        }
    } else {
        next() // 其他情况放行
    }
})

export default router
