export default {
    'menu.welcome': '歡迎',
    'menu.home': '首頁',
    'menu.fileview': '預覽',
    'menu.login': '登錄',
    'menu.account': '個人中心',
    'menu.account.login': '登錄',
    'menu.account.center': '個人中心',
    'menu.account.settings': '個人資訊',
    'menu.account.trigger': '觸發報錯',
    'menu.account.logout': '退出登錄',
    'menu.account.resources': '個人資源',
    'menu.user': '用戶管理',
    'menu.user.list': '用戶列表',
    'menu.user.role': '角色許可權',
    'menu.user.menu': '功能表管理',
    'menu.user.login': '登錄',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.exception.not-permission': '403',
    'menu.exception.not-find': '404',
    'menu.exception.server-error': '500',
    'menu.exception.trigger': '觸發錯誤',
    'menu.file-explorer': '文件處理',
    'menu.file-explorer.list': '我的素材',
    'menu.program': '印前項目',
    'menu.program.list': '我的項目',
    'menu.program.item': '專案資訊',
    'menu.jobs': '作業管理',
    'menu.jobs.list': '作業列表',
    'menu.customer': '客戶管理',
    'menu.customer.list': '客戶資訊',
    'menu.customer.contact': '連絡人',
    'menu.customer.contact_item': '連絡人詳情',
    'menu.customer.item': '客戶詳情',
    'menu.account.company': '我的企業',

    印路科技: '印路科技',

    '印丫云-让印前更简单': '印丫雲-让印前更简单',

    关闭: '關閉',

    印路印前: '印路印前',

    使用教程: '使用教程',
    激活成功: '啟動成功',

    激活: '啟動',
    '抱歉, 没有访问权限。': '抱歉, 没有访问权限。',
    返回首页: '返回首頁',
    '页面已更新，请刷新页面': '頁面已更新，請刷新頁面',
    '抱歉, 发生异常错误，请稍后再试。': '抱歉, 发生异常错误，请稍后再试。',
    发生错误: '發生錯誤',
    '发生错误！': '發生錯誤！',
    编号: '編號',
    企业编号: '企業編號',

    部门: '部門',
    部门类别: '部門類別',
    职务: '職務',
    籍贯: '籍貫',
    兴趣爱好: '興趣愛好',
    生日: '生日',
    年龄: '年齡',
    年龄区间: '年齡區間',
    教育程度: '教育程度',
    学校: '學校',
    备注: '備註',

    个人邮件: '個人郵件',
    联系方式: '聯繫方式',
    联系人关系: '連絡人關係',
    岗位信息: '崗位資訊',
    用户编号: '用戶編號',

    启用: '啟用',

    客户地址: '客戶位址',
    客户传真: '客戶傳真',
    客户网址: '客戶網址',
    客户邮编: '客戶郵編',
    经营信息: '經營資訊',
    客户关系: '客戶關係',
    成交信息: '成交資訊',
    负责人: '負責人',

    新建用户: '新建用戶',
    编辑用户: '編輯用戶',
    '用户名（姓名）手机号 邮箱 模糊检索': '用戶名（姓名）手機號 邮箱 模糊检索',
    账号: '帳號',

    再次录入密码: '再次錄入密碼',

    电子邮箱: '電子郵箱',
    角色权限: '角色許可權',
    角色名称: '角色名稱',
    新建角色: '新建角色',
    编辑角色: '編輯角色',
    上一级编号: '上一級編號',
    类型: '類型',
    菜单名称: '菜單名稱',
    菜单翻译key值: '菜單翻譯key值',
    菜单图标: '功能表圖示',
    排序: '排序',
    权限字符: '許可權字元',
    路由路径: '路由路徑',
    组件路径: '元件路徑',
    '属性关键词模糊搜索，支持回车搜索。': '屬性關鍵字模糊搜索，支援回車搜索。',
    新建客户: '新建客戶',

    '绑定成功！': '綁定成功！',

    '请输入8-15位密码': '請輸入8-15位密码',

    手机号错误: '手機號錯誤',

    请输入新邮箱: '請輸入新郵箱',

    您身边的印前专家: '您身邊的印前專家',

    忘记密码: '忘記密碼',

    关注印路科技公众号进行注册: '關注印路科技公眾號進行註冊',

    注册并登录: '註冊並登錄',

    账户设置: '帳戶設置',

    文件云盘: '文件雲盤',

    换绑邮箱: '換綁郵箱',
    设置密码: '設置密碼',

    '其他账户已经绑定该微信！': '其他帳戶已經綁定該微信！',

    绑定后即可用该邮箱号登录账号: '綁定後即可用該郵箱號登錄帳號',
    邮箱错误: '郵箱錯誤',
    '换绑后将无法使用该邮箱号登录此账号！': '換綁後將無法使用該郵箱號登錄此帳號！',
    现在邮箱: '現在郵箱',
    绑定后即可用该手机号登录账号: '綁定後即可用該手機號登錄帳號',

    现在手机号: '現在手機號',
    手机号找回: '手機號找回',
    邮箱号找回: '郵箱號找回',

    请绑定邮箱: '請綁定郵箱',
    '请选择.cfcurve文件': '請選擇.cfcurve文件',

    请选择pdf文件: '請選擇pdf檔',
    '请选择.tif文件': '請選擇.tif文件',

    添加印前工单项目: '添加印前工單項目',
    信息管理: '資訊管理',
    '正在生成，稍后请在【历史记录】中查看': '正在生成，稍後請在【歷史記錄】中查看',

    项目文件: '專案檔案',

    是否确认删除: '是否確認刪除',
    '个文件？': '個文件？',

    请选择至少一项: '請選擇至少一項',

    当前处于离线状态: '當前處於離線狀態',
    有新内容: '有新內容',
    '请点击“刷新”按钮或者手动刷新页面': '請點擊“刷新”按鈕或者手動刷新頁面'
}
