export default {
    必填项: 'Required',

    新建: 'Create',
    新增: 'Add New',
    编辑: 'Edit',
    搜索: 'Search',
    删除: 'Delete',
    批量启用: 'Batch enable',
    批量禁用: 'Batch disable',
    批量删除: 'Batch Delete',
    批量审批: 'Batch Approve',
    详情: 'Details',
    绑定: 'Bind',
    重置密码: 'Reset Password',
    设置权限: 'Set Permissions',
    查看: 'View',

    添加: 'Add',
    跳过: 'Skip',
    展开: 'Expand',
    收起: 'Collapse',
    登录: 'Log in',
    确定: 'Confirm',
    提交: 'Submit',
    修改: 'Modify',
    预览: 'Preview',
    下载: 'Download',
    上传文件: 'uploadfile',
    刷新: 'Refresh',
    警告: 'Warning',
    确认: 'Confirmation',
    取消: 'Cancel',
    已绑定: 'Bound',
    未绑定: 'Unbound',
    立即绑定: 'Bind now',
    解除绑定: 'Unbind now',
    关闭: 'Closed',
    注意: 'Attention',

    未设置: 'Not set',
    已设置: 'Already set',
    立即设置: 'Set now',

    '是否确认删除？': 'comfirm if deletion?',
    删除成功: 'Delete successfully!',
    更新成功: 'Update successfully!',
    上传成功: 'Upload successfully!',
    修改成功: 'Modify successfully!',
    绑定成功: 'Bind successfully!',
    添加成功: 'Add successfully!',
    上传失败: 'Upload failed',

    开启成功: 'Opened successfully',
    关闭成功: 'Closed successfully',

    天: 'Day',

    编号: 'Number',
    逻辑删除: 'soft deletion',
    乐观锁: 'optimistic locking',
    创建人id: 'Creator ID',
    创建人: 'Creator',
    创建时间: 'Created Time',
    修改人id: 'Modifier ID',
    修改人: 'Modifier',
    修改时间: 'Modification time',

    操作: 'Operation',
    启用: 'Enable',
    备注: 'Remarks',
    状态: 'Status',

    默认: 'Default',
    运行中: 'Running',
    已上线: 'Online',
    异常: 'Exception',

    禁用: 'Disable',

    开: 'On',
    关: 'Off',

    中国大陆: 'Mainland China',
    香港: 'Hong Kong',
    台湾: 'Taiwan',
    澳门: 'Macao',
    姓名: 'name',
    性别: 'gender',
    男: 'Male',
    女: 'Female',
    开启: 'On',
    地址: 'Address',

    是否启用: 'Whether to enable',
    查询: 'Inquire',
    重置: 'reset',

    标签: 'Label',
    卡盒: 'card box',
    软包: 'Soft Case',
    其它: 'other',

    文件: 'document',
    请输入搜索内容: 'Enter search content',
    请选择: 'please choose',
    中等: 'medium',
    全选: 'select all',
    取消全选: 'Deselect all',
    '确认是否取消选中？': 'Confirm to uncheck?',
    时: 'hour',
    分: 'minute',
    秒: 'Second',
    '登录已过期，请重新登录': 'Login has expired, please log in again',
    '网络请求异常，请稍后重试!': 'The network request is abnormal, please try again later!',
    删除目录: 'delete directory',
    复制: 'copy',
    请输入: 'please enter',
    上传: 'upload',
    分享: 'share',
    放大: 'enlarge',
    缩小: 'zoom out',
    返回: 'return',
    项目: 'project',
    设置: 'site',
    用户手册: 'User Manual',
    全屏: 'full screen',
    取消全屏: 'Cancel full screen',
    '是否确认取消？': 'Do you confirm the cancellation?',
    自定义: 'Custom',
    结果: 'Result',
    调试: 'debug',
    保存: 'save'
}
