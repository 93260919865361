export default {
    客户编号: 'customer_id',
    客户名称: 'customer_name',
    客户简称: 'customer_short_name',
    客户类别: 'type',
    客户分类: 'cate',
    客户状态: 'status',
    联系电话: 'phone',
    联系邮箱: 'email',
    请输入客户名称: 'Please enter the customer name',
    所属客户: 'Associated customer',
    请输入姓名: 'Please type in your name',
    请输入所属客户: 'Please enter your customer'
}
