import { createI18n } from 'vue-i18n'
import ZH from './zh-CN'
import EN from './en-US'
import TW from './zh-TW'
import config from '@/config'

const messages = {
    'zh-CN': { ...ZH },
    'en-US': { ...EN },
    'zh-TW': { ...TW }
}

export default createI18n({
    locale: config?.locale ?? 'zh-CN', // 设置当前语言类型
    legacy: false, // 如果要支持compositionAPI，此项必须设置成false
    globalInjection: true, // 全局注册$t方法
    messages,
    silentTranslationWarn: true,
    missingWarn: false,
    silentFallbackWarn: true,
    fallbackWarn: false
})
