export default {
    请选择: 'Please select',
    文件: 'File',
    文件不能大于50M: 'File size cannot exceed 50M.',
    标记: 'Marketing tools',
    '仅支持".pdf"格式文件': "Only support '.pdf' format files",
    微穴种子: 'seed',
    '仅支持".tif"格式文件': "only support '.tif' format files",
    补偿曲线: 'dgc',
    '仅支持".cfcurve"格式文件': "only support '.cfcurve' format files.",
    刀模: 'Daomu',
    '仅支持".json"格式文件': "only support '.json' format files.",
    mark: 'Mark',
    seed: 'Seed',
    dgc: 'DGC',
    arg: 'Args',
    daomu: 'Daomu',
    'resources.10201.name': 'Mark',
    'resources.10202.name': 'Seed',
    'resources.10203.name': 'DGC',
    'resources.10206.name': 'Daomu',
    'resources.10205.name': 'Args',
    'resources.1020501.name': 'Preflight',
    'resources.1020502.name': 'Trapping',
    'resources.1020503.name': 'Comparison',
    'resources.1020504.name': 'Imposition',
    'resources.1020505.name': 'RIP',
    'resources.1020506.name': 'LenToTIFF',
    'resources.1020507.name': 'Approval',
    'resources.1020508.name': 'Smart',
    'resources.1020509.name': 'Spotting',
    'resources.1020510.name': 'Image Enhancement',
    'resources.1020511.name': '3D Ray Tracing',
    'resources.1020512.name': 'Color Conversion',
    'resources.1020513.name': 'VDP',
    'resources.1020514.name': 'Soft package order imposition',
    'resources.1020517.name': 'Cutting die typesetting',
    'resources.1020518.name': 'Tiff precision conversion'
}
