export default {
    节点参数: 'Node parameter',
    流程终止: 'Process termination',
    有错误: 'At fault',
    有错误与警告: 'There are errors and warnings',
    不限制: 'No limit',
    所有审批审核失败: 'All approvals failed',
    结果文件: 'Result file',
    报告文件: 'Report file',
    流程: 'flow',
    全部: 'all',
    印前: 'prepress',
    流程图像: 'Process image',
    流程名称: 'Process name',
    流程简介: 'Process introduction',
    流程配置: 'Flow configuration'
}
