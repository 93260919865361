export default {
    项目: 'project',
    客户: 'client',
    企业: 'company',
    资源: 'resource',
    语言: 'Language',
    个人: 'personal',
    个人信息: 'Personal Information',
    退出登录: 'Logout',
    客户信息: 'Customer List',
    联系人: 'contact',
    全屏展示: 'Full screen display',
    取消全屏: 'Cancel full screen',
    项目栏目: 'Project column'
}
