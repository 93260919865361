export default {
    必填项: '必填項',

    新建: '新建',
    新增: '新增',
    编辑: '編輯',
    搜索: '搜索',
    删除: '刪除',
    批量启用: '批量啟用',
    批量禁用: '批量禁用',
    批量删除: '批量刪除',
    批量审批: '批量審批',
    详情: '詳情',
    绑定: '綁定',
    重置密码: '重置密碼',
    设置权限: '設置許可權',
    查看: '查看',

    添加: '添加',

    跳过: '跳過',
    展开: '展開',
    收起: '收起',
    登录: '登錄',
    确定: '確定',
    提交: '提交',
    修改: '修改',
    预览: '預覽',
    下载: '下載',
    上传文件: '上傳文件',
    刷新: '刷新',
    警告: '警告',
    确认: '確認',
    取消: '取消',
    已绑定: '已綁定',
    未绑定: '未綁定',
    立即绑定: '立即綁定',
    解除绑定: '解除綁定',
    关闭: '關閉',
    注意: '注意',

    未设置: '未設置',
    已设置: '已設置',
    立即设置: '立即設置',

    '是否确认删除？': '是否確認刪除？',
    删除成功: '刪除成功',
    更新成功: '更新成功',
    上传成功: '上傳成功',
    修改成功: '修改成功',
    绑定成功: '綁定成功',
    添加成功: '添加成功',
    上传失败: '上傳失敗',

    开启成功: '開啟成功',
    关闭成功: '關閉成功',

    天: '天',

    编号: '編號',
    逻辑删除: '邏輯刪除',
    乐观锁: '樂觀鎖',
    创建人id: '創建人id',
    创建人: '創建人',
    创建时间: '創建時間',
    修改人id: '修改人id',
    修改人: '修改人',
    修改时间: '修改時間',
    操作: '操作',
    启用: '啟用',
    备注: '備註',
    状态: '狀態',

    默认: '默認',
    运行中: '運行中',
    已上线: '已上線',
    异常: '異常',

    禁用: '禁用',
    开: '開',
    关: '關',

    中国大陆: '中國大陸',
    香港: '香港',
    台湾: '臺灣',
    澳门: '澳門',

    姓名: '姓名',
    性别: '性別',
    男: '男',
    女: '女',
    开启: '開啟',

    地址: '地址',
    是否启用: '是否啟用',
    查询: '查詢',
    重置: '重置',
    标签: '標籤',
    卡盒: '卡盒',
    软包: '軟包',
    其它: '其它',

    文件: '文件',
    请输入搜索内容: '請輸入搜索內容',
    请选择: '請選擇',

    中等: '中等',
    紧凑: '緊湊',
    全选: '全選',
    取消全选: '取消全選',
    '确认是否取消选中？': '確認是否取消選中？',
    时: '時',
    分: '分',
    秒: '秒',
    '登录已过期，请重新登录': '登錄已過期，請重新登錄',
    '网络请求异常，请稍后重试!': '網路請求異常，請稍後重試!',
    删除目录: '刪除目錄',
    复制: '複製',
    请输入: '請輸入',
    上传: '上傳',
    分享: '分享',
    放大: '放大',
    缩小: '縮小',
    返回: '返回',
    项目: '項目',
    设置: '設置',
    用户手册: '用戶手冊',
    全屏: '全屏',
    取消全屏: '取消全屏',
    '是否确认取消？': '是否確認取消？',
    自定义: '自定義',
    结果: '結果',
    调试: '調試',
    保存: '保存'
}
