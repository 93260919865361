export default {
    到期时间: '到期时间',
    您还未加入企业: '您还未加入企业',
    人员信息: '人员信息',
    仅看自己印前项目: '仅看自己印前项目',
    所有成员印前项目: '所有成员印前项目',
    我的客服: '我的客服',
    工作日: '工作日',
    印前服务: '印前服务',
    本年已服务次数: '本年已服务次数',
    服务内容包含: '服务内容包含',
    '1、精准的标记文件制作：包括切割线、折叠线和颜色校验条等，确保印刷过程中的准确性和效率。':
        '1、精准的标记文件制作：包括切割线、折叠线和颜色校验条等，确保印刷过程中的准确性和效率。',
    '2、高质量微穴种子制作：制作精细的屏幕点图案，以优化印刷品的质感和色彩深度。':
        '2、高质量微穴种子制作：制作精细的屏幕点图案，以优化印刷品的质感和色彩深度。',
    '3、补偿曲线定制：会员定制补偿曲线，解决由纸张、油墨等因素引起的偏差，确保印刷输出忠实于原始设计。':
        '3、补偿曲线定制：会员定制补偿曲线，解决由纸张、油墨等因素引起的偏差，确保印刷输出忠实于原始设计。',
    '4、VDP可变数据文件制作：可变数据打印（VDP）文件制作服务，将会员客户的个性化数据集成到印刷物中。':
        '4、VDP可变数据文件制作：可变数据打印（VDP）文件制作服务，将会员客户的个性化数据集成到印刷物中。'
}
