export default {
    '您的浏览器不支持播放声音。': 'Your browser does not support the playback of sound',
    '您的浏览器不支持播放视频。': 'Your browser does not support the playback of videos',
    印前项目: 'Prepress Projects',
    已选文件: 'checked files',
    目录文件: 'catalog file',
    项目目录: 'Project directory',
    '文件尺寸过大，无法上传': 'File size is too large to upload',
    上传失败: 'Upload failed',
    上传原件: 'Upload original file',
    修改参数: 'Modify parameters',
    参数: 'Parameter',
    原件: 'Original file',
    生成文件: 'Generate file',
    历史记录: 'History record',
    项目信息: 'Project information',
    删除项目: 'Delete project',
    可选功能: 'optional features',
    更多功能: 'More features',
    文件处理: 'File processing',
    '本功能暂未开启，请联系客户经理开启':
        'This function is not yet available. Please contact customer manager (mailto:liukun@printroad.cn) to activate',
    请选择文件: 'Please select a file',
    项目编号: 'program_id',
    项目类型: 'program_type',
    项目名称: 'program_name',
    项目客户: 'customer_name',
    请输入项目名称: 'Please enter project name',
    联系人: 'contact',
    版面参数: 'Page layout parameters',
    文件参数: 'File parameters',
    文件默认参数: 'File default parameter',
    等: 'Etc',
    个文件: 'files',
    必须选择: 'Must select',

    印刷方式: 'Type',
    陷印距离: 'Trapping Distance',
    '最大值3mm,最小值-3mm': 'Max3mm,Min-3mm',
    标记: 'Mark',
    宽度: 'width',
    '最大值3000mm,最小值20mm': 'Max3000mm,Min20mm',
    宽度不能为负数: 'The width cannot be negative',
    高度: 'height',
    高度不能为负数: 'The height cannot be negative',
    咬口: 'OffsetY',
    '最大值30mm,最小值0mm': 'Max30mm,Min0mm',
    咬口不能为负数: 'The OffsetY cannot be negative',
    横向数量: 'colls',
    '最大值100,最小值1': 'Max100,Min1',
    横向间距: 'hspace',
    '横向间距.tips1': 'Max500mm,Min-500mm',
    纵向数量: 'rows',
    纵向间距: 'vspace',
    '最大值500mm,最小值-500mm': 'Max500mm,Min-500mm',
    方向: 'direction',
    缺省出血: 'bleed',
    '最大值10mm,最小值0mm': 'Max10mm,Min0mm',
    单元间距: 'margin',
    补偿曲线: 'curve',
    RIP分辨率: 'Resolution',
    RIP网点形状: 'Screen Dots',
    加网线数: 'Screen Num',
    '最大值400,最小值50': 'Max400,Min50',
    最小网点尺寸: 'Screen Min',
    '最大值200,最小值5': 'Max200,Min5',
    过度点: 'Point',
    水平变形率: 'Hdistortion',
    '最大值110,最小值90': 'Max110,Min90',
    垂直变形率: 'Vdistortion',
    '最大值20,最小值0': 'Max20,Min0',
    网点角度: 'Screen Type',
    是否推送审批链接至邮箱: 'Whether to push the approval link to the email',
    邮箱: 'email',
    请输入正确的邮箱: 'Please enter the correct email address',
    是否推送链接到我的微信: 'Whether to push the link to my wechat',
    选择微穴种子文件: 'pattern',
    网墙宽度: 'CellWall',
    '最大值3mm,最小值0mm': 'Max3mm,Min0mm',
    最小网点: 'SkipSmall',

    是否使用网墙微穴: 'wallpattern_use',
    网墙微穴种子文件: 'wallpattern',
    是否使用切边微穴: 'edge_use',
    切边微穴种子文件: 'edge',
    切边高度: 'edge height',
    '最大值3mm,最小值0.01mm': 'Max3mm,Min0.01mm',
    是否区分实地和非实地: 'nosolid_use',
    非实地微穴加网: 'nosolid',
    数量: 'count',
    '最大值10000,最小值0': 'Max10000,Min0',
    已处理文件归集: 'The processed files are collected',
    '将已处理的原件文件在"原件"文件夹删除，归集到"混拼-混拼原件"文件夹，混拼结果文件放入"混拼-结果文件"文件夹。':
        'Delete the processed original files in the "Originals" folder, save them to the "Mixing-Mixing-originals" folder, and put the mixing-result files into the "Mixing-result files" folder.',
    模型: 'model',
    名称: 'name',
    输出模式: 'Output File Combine Modes',
    每个文件页数: 'Split document after page count',
    从哪行数据开始: 'Start From Item',
    最小值1: 'Min 1',
    执行数据行数: 'Number of Output Items',
    '0表示全部': '0 means all',
    结束必须大于起始: 'end > start',

    版号: 'Version Number',
    '单摸-背旋转方向': 'Single touch - back rotation direction',
    '单摸-正旋转方向': 'Single touch - positive rotation direction',
    版长: 'Edition length',
    周长: 'perimeter',
    左走空: 'Going short left',
    左跟踪宽度: 'left tracking width',
    '左出血-背': 'Bleeding left - back',
    '右出血-背': 'Right bleeding - back',
    '左出血-底': 'Left bleeding-bottom',
    '右出血-底': 'Right bleeding-bottom',
    '左出血-正': 'Left bleeding - positive',
    '右出血-正': 'Right bleeding - positive',
    上出血: 'Upper bleeding',
    下出血: 'Lower bleeding',
    右走空: 'Short right',
    右跟踪宽度: 'right tracking width',
    是否制袋: 'Whether to make bags',
    数量要求: 'Quantity requirements',
    规格尺寸: 'Standard sizes',
    袋型: 'bag type',
    开口方向: 'opening direction',
    封边宽度: 'Edge width',
    撕口位置: 'Tear position',
    圆角: 'rounded corners',
    双切刀: 'Double cutter',
    吊挂孔: 'hanging hole',
    孔位置: 'hole location',
    烫刀形状: 'Hot knife shape',
    拉链类型: 'Zipper type',
    模切要求: 'Die cutting requirements',
    拉链位置: 'zipper position',
    尺寸确认: 'Size confirmation',
    封口牢度: 'Sealing fastness',
    漏气: 'Air leakage',
    图文制作: 'Graphic and text production',
    印刷要求: 'printing requirements',
    细节确认: 'Details confirmed',
    制袋操作: 'bag making operation',
    完成时间: 'Complete time',
    是否制作: 'Whether to make',
    图稿标准: 'Artwork standards',
    稿件确认方式: 'Manuscript confirmation method',
    色样标准: 'color sample standard',
    色样确认方式: 'Color sample confirmation method',
    拼数: 'Pin number',
    单元规格: 'Unit specifications',
    其他要求: 'other requirements',
    稿件名称: 'Manuscript title',
    印前操作: 'Prepress operations',
    是否印刷: 'Whether to print',
    自带料: 'Bring your own materials',
    自带料位置: 'Bring your own material location',
    '材料/规格': 'Materials/Specifications',
    有效印宽: 'Effective printing width',
    出卷方向: 'Unwinding direction',
    印刷数量: 'Printing quantity',
    头出: 'Head out',
    '头出 90度': 'Head out 90 degrees',
    尾出: 'Tail out',
    '尾出 90度': 'Tail out 90 degrees',
    无要求: 'no request',
    文件下载: 'Download Document',
    访问密码: 'access password',
    有效期: 'Validity period',
    支持: 'support',
    创建分享链接: 'Create sharing link',
    '请输入访问密码，非必填': 'Please enter the access password, optional',
    创建成功: 'Created successfully',
    分享链接创建成功: 'Sharing link created successfully',
    复制成功: 'Copied successfully',
    保存参数: 'Save parameters',
    将当前参数保存为参数文件: 'Save current parameters as parameter file',
    文件保存位置: 'File save location',
    资源: 'resource',
    功能参数: 'Function parameter',
    文件名称: 'file name',
    输入文件名称: 'Enter file name',
    参数文件: 'parameter file',
    选择参数文件: 'Select parameter file',
    请输入要搜索的文件: 'Please enter the file you want to search for',
    更多设置: 'More Settings',
    文件尺寸不能大于: 'File size cannot be larger than',
    最多只能选择15个文件进行处理: 'A maximum of 15 files can be selected for processing',
    项目设置: 'Item setting',
    混拼: 'conflation',
    标记文件变量值自定义: 'Tag file variable values custom',
    自定义值: 'Custom value',
    变量自定义: 'Variable customization',
    '当前文件上传中，暂时无法删除': 'The current file is uploaded and cannot be deleted',
    '最大值10160,最小值72': 'Maximum 10160, minimum 72',
    精度: 'precision',
    点击上传: 'Click to upload',
    不支持该类型文件查看: 'This type of file cannot be viewed',
    自定义网点角度: 'Customize the dot Angle',
    新增网点角度: 'Angle of new network',
    度: 'Angle',
    '其他专色默认为45°': 'Other spot colors default to 45°',
    上传中: 'Up cross',
    检索半色调: 'Retrieval halftone',
    文件数量不能大于: 'The number of files cannot be greater than',
    文件数量必须大于: 'The number of files must be greater than',
    描述文本: 'Description text',
    选择方案配置: 'Select scheme configuration',
    文件初始化中请稍后: 'File initialization later',
    拼版模式: 'Mosaic mode',
    传统: 'tradition',
    数码: 'Digital code',
    请选择标记: 'Please select tag',
    请选择微穴种子文件: 'Please select the micro seed file',
    请选择网墙微穴种子文件: 'Please select the network wall micro hole seed file',
    请选择切边微穴种子文件: 'Please select the cut edge micro hole seed file',
    出血距离: 'Bleeding distance',
    '标签文件出血条件：文件中的刀线油墨需命名：die、diecut、cut、刀模':
        'Label file bleeding conditions: The knife ink in the file must be named: die, diecut, cut, and die',
    页面范围: 'Page range',
    全部: 'All',
    第一页: 'First page',
    选定范围: 'Selection range',
    格式: 'format',
    高分辨率图像: 'High resolution image',
    最大值: 'Maximum value',
    最小值: 'Minimum value',
    检查蒙版: 'Check mask',
    降精度: 'Precision reduction',
    请选择: 'Please select',
    正常: 'normal',
    警告: 'warn',
    错误: 'mistake',
    高分辨率位图: 'High resolution bitmap',
    低分辨率图像: 'Low resolution image',
    低分辨率位图: 'Low resolution bitmap',
    检测条形码: 'Detection bar code',
    识别: 'recognize',
    混合油墨条形码: 'Mixed ink bar code',
    非嵌入字体: 'Unembedded font',
    缺少字体符号: 'Missing font symbol',
    子集字体: 'Subset font',
    混合油墨文本: 'Mixed ink text',
    文本对象: 'Text object',
    矢量化: 'vectorization',
    字体大小: 'Font size',
    最少: 'minimum',
    等于: 'Equal to',
    最多: 'At most',
    至少包含分色数量: 'Contains at least the number of separations',
    分析文本对象: 'Analyze text object',
    RGB对象: 'RGB object',
    分析图像对象: 'Analyze image objects',
    分析其他对象: 'Analyze other objects',
    转换为CMYK: 'Convert to CMYK',
    lab对象: 'lab object',
    灰色对象: 'Gray object',
    仅在CMYK图像中: 'Only in CMYK images',
    仅在图像中: 'Only in the image',
    在所有对象中: 'In all objects',
    移除: 'Remove',
    镂空黑色物体: 'Hollow black object',
    进行叠印: 'overprint',
    镂空黑色文本: 'Hollow black text',
    最大宽度: 'Maximum width',
    镂空黑色边框: 'Hollow black border',
    镂空黑色矩形: 'Hollow black rectangle',
    分析填充: 'Analytical filling',
    分析边框: 'Analysis border',
    镂空的技术油墨: 'Hollow technical ink',
    镂空的光油墨: 'Hollow light ink',
    镂空的不透明油墨: 'Hollow opaque ink',
    叠印的白色物体: 'Overprinted white objects',
    移除叠印: 'Remove overprint',
    混合油墨对象: 'Mixed ink object',
    '技术/非技术油墨混合': 'Technical/non-technical ink mix',
    未使用的分色: 'Unused color separation',
    去除: 'remove',
    颜色: 'colour',
    至: 'to',
    加网物件: 'Netted object',
    移除加网: 'Remove screening',
    XMP加网信息: 'XMP networking information',
    设置为默认值: 'Set to the default value',
    未加网对象: 'Unnetted object',
    查找未加网的对象: 'Finds an unnetted object',
    查找缺少分色项的加网: 'Finds mesh that lacks a color separation',
    查找缺少分色的未加网对象: 'Finds an unscreened object that lacks color separation',
    边框: 'border',
    分色计数: 'Color separation count',
    笔画宽度: 'Stroke width',
    修复: 'Repair',
    开放路径: 'Open path',
    关闭: 'Off',
    建议的替换文案: 'Suggested replacement copy',
    替换: 'Replace',
    印版宽度: 'Plate width',
    印版高度: 'Plate height',
    纸张宽度: 'Paper width',
    纸张高度: 'Paper height',
    纸张参考点: 'Paper reference point',
    上左: 'Top left',
    上中: 'Upper middle school',
    上右: 'Top right',
    左: 'On the left',
    中心: 'Centre',
    右: 'right',
    下左: 'Lower left',
    下中: 'Lower median',
    下右: 'Lower right',
    纸张水平偏移: 'Paper horizontal shift',
    纸张垂直偏移: 'Paper vertical offset',
    拼版参考点: 'Mosaic reference point',
    拼版水平偏移: 'Patchwork horizontal offset',
    拼版垂直偏移: 'Mosaic vertical offset',
    左间距: 'Left space',
    右间距: 'Right margin',
    上间距: 'Upper space',
    下间距: 'Lower space',
    单模文件页码: 'Page number of single-mode document',
    裁切路径: 'Crop path',
    媒体框: 'Media frame',
    裁剪框: 'Crop box',
    修剪框: 'Trim frame',
    出血框: 'Bleed frame',
    编辑框: 'Edit box',
    根据单模设置: 'Set according to single mode',
    重复方向: 'Repetitive direction',
    上: 'Up',
    下: 'Down',
    镜像: 'Mirror image',
    左右: 'around',
    上下: 'Up and down',
    水平拼数: 'Horizontal spelling',
    垂直拼数: 'Vertical spelling',
    水平偏移: 'Horizontal migration',
    垂直偏移: 'Vertical migration',
    水平间隙: 'Horizontal clearance',
    垂直间隙: 'Vertical clearance',
    交错方向: 'Alternate direction',
    交错偏移距离: 'Staggered offset distance',
    交错偏移比例: 'Interleaved migration ratio',
    重新排列: 'rearrange',
    列: 'Column',
    将额外的台版添加到: 'Add additional console versions to',
    奇数: 'Odd number',
    偶数: 'Even number',
    包括部分一键启动: 'Includes partial one-click start',
    交错转向: 'Staggered steering',
    头对头: 'Head to head',
    头对尾: 'Head to tail',
    尾对尾: 'tail to tail',
    尾对头: 'tail to head',
    交错行间距类型: 'Interleaved line spacing type',
    行的间隙: 'Row gap',
    列的间隙: 'Column gap',
    交错行间距个数: 'Number of interleaved line spacing',
    交错行间距长度: 'Interlaced line spacing length',
    生成单模号: 'Generate a single mode number',
    单模编号前缀: 'Single mode number prefix',
    单模编号样式: 'Single mode numbering style',
    数字: 'figure',
    罗马数字: 'Roman numeral',
    单模编号起始位置: 'Single mode number start position',
    左上: 'Upper left',
    右上: 'Upper right',
    右下: 'Lower right',
    左下: 'Lower left',
    单模编号排列方向: 'Single mode numbering arrangement direction',
    横向: 'Landscape orientation',
    横向蛇形: 'Transverse serpentine',
    竖向: 'vertical',
    竖向蛇形: 'Vertical serpentine',
    出血: 'hemorrhage',
    获取单模的出血距离: 'The bleeding distance of a single mode was obtained',
    水平无缝连接: 'Horizontal seamless connection',
    垂直无缝连接: 'Vertical seamless connection',
    从第一个区块开始应用变量: 'Apply the variable from the first block',
    到黑色: 'To black',
    到斑点: 'To spot',
    到不透明: 'To opaque',
    重叠: 'overlap',
    反向陷印: 'Reverse trapping',
    强度: 'intensity',
    回拉: 'Pull back',
    允许所有: 'Allow all',
    压平部分: 'Flattening part',
    黑色压平部分: 'Black flatten part',
    无: 'There is no',
    回拉类型: 'Pullback type',
    两者: 'both',
    单个: 'single',
    只剩一个色: 'Only one color left',
    回拉来源: 'Pullback source',
    所有: 'own',
    仅限白色: 'White only',
    回拉距离: 'Pull-back distance',
    边角: 'corner',
    截断: 'truncation',
    允许对象相关陷印: 'Allows object-related trapping',
    保持对象形状: 'Hold object shape',
    斜角: 'bevel',
    尖角: 'miter',
    直角: 'square',
    中心截断: 'center',
    边缘截断: 'edge'
}
