import login from './login'
import component from './component'
import message_code from './message_code'
import assembly from './assembly'
import pages from './pages'
import layout from './layout'
import account from './account'
import resources from './resources'
import company from './company'
import user from './user'
import project from './project'
import shared from './shared'
import formConfig from './formConfig'
import flow from './flow'

export default {
    ...login,
    ...component,
    ...message_code,
    ...assembly,
    ...pages,
    ...layout,
    ...account,
    ...resources,
    ...company,
    ...user,
    ...project,
    ...shared,
    ...formConfig,
    ...flow
}
