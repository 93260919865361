export default {
    节点参数: '节点参数',
    流程终止: '流程终止',
    有错误: '有错误',
    有错误与警告: '有错误与警告',
    不限制: '不限制',
    所有审批审核失败: '所有审批审核失败',
    结果文件: '结果文件',
    报告文件: '报告文件',
    流程: '流程',
    全部: '全部',
    印前: '印前',
    流程图像: '流程图像',
    流程名称: '流程名称',
    流程简介: '流程简介',
    流程配置: '流程配置'
}
