<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { channel_app_query } from '@/service'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { useLanguageStore } from '@/stores/language'
import { useChannelAppStore } from '@/stores/channelApp'
import { RequestCodeEnum } from '@/enums/requestEnums'
import { isMobile } from '@/utils'
const router = useRouter()
// 获取路由参数
// const { params, query } = route;
const languagestore = useLanguageStore()
const { locale } = storeToRefs(languagestore)
const colorPrimary = ref('#00baab')

const loading = ref(false)

const channelAppStore = useChannelAppStore()

const onload = async prefix => {
    loading.value = false
    const res = await channel_app_query({ app_namespace_prefix: prefix })
    if (res.code === RequestCodeEnum.OK) {
        channelAppStore.setChannelApp(res?.data ?? {})
        if (res?.data?.app_name) {
            document.title = res?.data?.app_name
        }
    }
    loading.value = true
}

const printroadPrefixArr = ['prepress', 'test-prepress', 'dev-prepress']
const getPrefix = (prefix?: string) => (prefix && !printroadPrefixArr.includes(prefix) ? prefix + '.' : '')

const appResize = (prefix?: string) => {
    if (isMobile()) {
        if (import.meta.env.MODE === 'production') {
            window.location.replace(`https://${getPrefix(prefix)}mobile-prepress.printroad.cn`)
        } else if (import.meta.env.MODE === 'test') {
            window.location.replace(`https://${getPrefix(prefix)}test-mobile-prepress.printroad.cn`)
        } else {
            window.location.replace(`https://${getPrefix(prefix)}dev-mobile-prepress.printroad.cn`)
        }
    }
}

const init = name => {
    const hostname = window?.location?.hostname ?? ''
    const prefix = hostname.split('.')?.[0] ?? ''
    if (name !== 'shared') {
        appResize(prefix)
    }
    if (prefix) {
        onload(prefix)
    } else {
        channelAppStore.setChannelApp({})
    }
}

router.beforeEach((to, _, next) => {
    init(to.name)
    next() // 必须调用next()来resolve这个钩子
})
</script>

<template>
    <a-config-provider
        :locale="locale === 'en-US' ? enUS : zhCN"
        :theme="{ token: { colorPrimary: colorPrimary } }"
        v-if="loading"
    >
        <router-view />
    </a-config-provider>
</template>
