import { request } from '@/libs/axios'
import type { ResponseData } from '@/libs/axios/baseType'
import { ContentTypeEnum } from '@/enums/requestEnums'

// 获取用户、公司信息
export async function get_my_info() {
    return await request.post<ResponseData<MyInfo>>('/user/me/info', {})
}

// 激活码续费
export async function cdkAdd(param: any) {
    return await request.post<ResponseData<any>>('/cdkey/add', param)
}

// 查询渠道商的自定义入口信息
export async function channel_app_query(param: any) {
    return await request.post<ResponseData<any>>('/3/t_user/channel_app/queryByAppNamespacePrefix', param)
}

// 查询参数
export async function arg_file_list(params: any) {
    const res = {
        ...params,
        page: {
            current: 1,
            size: 1000
        }
    }
    return await request.post<ResponseData<any>>('/3/t_printing/program_config/arg_file/list', res)
}

// 根据skill_id查询默认配置的form_default_option_config_id(config_id)
export async function form_default_option_query(params: any) {
    return await request.post<ResponseData<any>>('/3/form_default_option/query', params)
}

// RIP网点角度选项查询
export async function config_list(params: any) {
    return await request.post<ResponseData<any>>('/3/t_printing/config/list', params)
}

// { upload_type: "private", file_type: "file_explorer" }
export async function get_upload_token(params: any) {
    return request.post('/oss/get_upload_token', params)
}
export async function upload(params: any, onProgress?: any) {
    return request('/oss/upload', {
        data: params,
        headers: { 'Content-Type': ContentTypeEnum.FORM_DATA },
        timeout: 300000,
        onUploadProgress: (ev: any) => {
            // ev - axios 上传进度实例，上传过程触发多次
            // ev.loaded 当前已上传内容的大小，ev.total - 本次上传请求内容总大小
            const percent = (ev.loaded / ev.total) * 100
            // 计算出上传进度，调用组件进度条方法
            onProgress?.({ percent })
        }
    })
}

// 获取用户功能权限
export async function get_program_functions() {
    return await request.post<ResponseData<any>>('/3/t_printing/program/functions')
}
