const preinstallless = () => {
    // JavaScript 文件
    const screenWidth = window.innerWidth || document.documentElement.clientWidth

    // 将 screenWidth 的值传递给 LESS 变量
    document.documentElement.style.setProperty('--screen-width', `${screenWidth}px`)
    ;(function (doc, win) {
        var docE1 = doc.documentElement,
            resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
            recalc = function () {
                var clientWidth = docE1.clientWidth
                if (!clientWidth) return
                // const designWidth = 1920 // 设计稿宽度
                // const baseFontSize = 100 // 在设计稿中 1rem 相当于 100px
                if (clientWidth >= 750) {
                    docE1.style.fontSize = '100px'
                } else {
                    // docE1.style.fontSize = baseFontSize * (clientWidth / designWidth) + 'px'
                    docE1.style.fontSize = '80px'
                }
            }
        if (!doc.addEventListener) return
        win.addEventListener(resizeEvt, recalc, false)
        doc.addEventListener('DOMContentLoaded', recalc, false)
    })(document, window)
}

export default () => {
    preinstallless()
}
