export default {
    语言设置: '語言設置',
    使用教程: '使用教程',
    其他方式登录: '其他方式登錄',
    账号注册: '帳號註冊',
    返回登录: '返回登錄',
    找回密码: '找回密碼',
    '想起密码？': '想起密碼？',
    去登录: '去登錄',
    '微信扫码登录/注册': '微信掃碼登錄/注册',
    '验证登录/注册': '驗證登錄/注册',
    请输入手机号: '請輸入手機號',
    请输入正确的手机号: '請輸入正確的手機號',
    请输入验证码: '請輸入驗證碼',
    秒后重新获取: '秒後重新獲取',
    获取验证码: '獲取驗證碼',
    未注册手机号将自动创建账号: '未註冊手機號將自動創建帳號',
    登录: '登錄',
    '发送成功,请填写收到的验证码': '發送成功,请填写收到的验证码',
    登录成功: '登錄成功',
    账号登录: '帳號登錄',
    '输入8-15位密码': '輸入8-15位密码',
    '忘记密码？': '忘記密碼？',
    确认密码错误: '確認密碼錯誤',
    请确认密码: '請確認密碼',
    验证码已发送: '驗證碼已發送',
    注册成功: '註冊成功',
    '重置成功，请登录！': '重置成功，請登錄！',
    微信: '微信',
    手机验证: '手機驗證',
    账号密码: '帳號密碼',
    '欢迎您登录印丫云！': '歡迎您登錄印丫雲！',
    绑定手机号: '綁定手機號',
    '手机号是否绑定新微信？': '手機號是否綁定新微信？',
    '绑定手机号获得更高级的安全保护，同时您可以直接使用手机号登录印路所有产品，方便快捷':
        '綁定手機號獲得更高級的安全保護，同時您可以直接使用手機號登錄印路所有產品，方便快捷',
    账号已注册: '帳號已註冊',
    登录或注册即同意: '登錄或註冊即同意',
    平台服务协议: '平臺服務協定',
    隐私政策: '隱私政策',
    和: '和',
    法律声明: '法律聲明',
    '欢迎您登录！': '歡迎您登錄！'
}
