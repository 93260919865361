import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config'

export const useLanguageStore = defineStore(
    'locale',
    () => {
        const locale = ref(config?.locale)
        function setLocale(data: string) {
            locale.value = data
        }

        return { locale, setLocale }
    },
    {
        persist: {
            key: 'LOCALE',
            storage: window.localStorage, // storage: window.sessionStorage,
            paths: ['locale']
        }
    }
)
