export default {
    '1010102': 'Preflight',
    '1010103': 'Trapping',
    '1010104': 'Comparison',
    '1010105': 'Imposition',
    '1010106': 'RIP',
    '1010107': 'LenToTIFF',
    '1010108': 'Approval',
    '1010109': 'Smart',
    '1010110': 'Spotting',
    '1010111': 'Image Enhancement',
    '1010112': '3D Ray Tracing',
    '1010113': 'Color Conversion',
    '1010114': 'VDP',
    '1010115': 'Soft package order imposition',
    '1010116': 'Multi-page PDF split',
    '1010117': 'Cutting die typesetting',
    '1010118': 'Tiff precision conversion',
    '1010119': 'Auto bleed',
    '1010151': 'Smart cutout',
    '1010153': 'image association',
    '1010154': 'Intelligent image expansion',
    '1010155': 'Smart erase',
    '1010156': 'sketch',
    '1010157': 'Convert Text To Picture',
    '1010199': 'G2P',

    '00_原件': 'Original',
    '01_预检': 'Preflight',
    预检文件: 'preflight file',
    预检报告: 'Preflight report',
    '02_陷印': 'Trap',
    '03_对比': 'Compared',
    对比记录: 'Compare records',
    对比报告: 'Comparison report',
    '04_拼版': 'imposition',
    '05_RIP': 'RIP',
    '06_LenToTIFF': 'LenToTIFF',
    '07_审批': 'Approval',
    '08_智能柔印': 'Smart flexo printing',
    '09_混拼': 'mix',
    '10_画质增强': 'Image quality enhancement',
    '11_3D光线追踪': '3D ray tracing',
    '12_色彩转换': 'color conversion',
    '13_VDP': 'VDP',
    '14_软包订单拼版': 'Soft package order imposition',
    '16_多页PDF拆分': 'Multi-page PDF split',
    '17_刀模拼版': 'Cutting die typesetting',
    '18_Tiff精度转换': 'Tiff precision conversion',
    '19_自动出血': 'Auto bleed',
    '51_智能抠图': 'Smart cutout',
    '53_图像联想': 'image association',
    '54_智能扩图': 'Intelligent image expansion',
    '55_智能擦除': 'Smart erase',
    '56_线稿': 'Image to Line Draft ',
    '57_文生图': 'Convert Text To Picture',
    '99_G2P': 'G2P',
    
    '自动检测文件合规性，生成详尽的报告。': 'Automatically detect file compliance and generate detailed reports.',
    '补偿的对位误差，防止出现未印刷的空隙。': 'Offset alignment errors to prevent unprinted gaps.',
    '快速检测文件变化，并生成清晰的对比报告。': 'Quickly detect file changes and generate clear comparison reports.',
    '轻松实现对文件的灵活拼贴和混排。': 'Easily implement flexible collage and mixing of files.',
    '进行自动化分色处理和个性化参数设置。': 'Automatic color separation processing and personalized parameter setting.',
    '便捷地将Len格式文件转换为TIFF格式。': 'Easily convert Len files to TIFF.',
    '支持多渠道抄送确认，并保存在云端。': 'Support multi-channel CC confirmation, and save in the cloud.',
    '优化传统柔版印刷过程。': 'Optimize the traditional flexographic printing process.',
    '轻松实现文件的多元化排列和组合。': 'Easily achieve diversified file arrangement and combination.',
    '提升印前文件的图像质量，确保印刷品质更为清晰。':
        'Improve the image quality of prepress documents to ensure sharper print quality.',
    对印前文件的三维图像高清光线渲染: 'Hd light rendering of 3D images of prepress files.',
    '保证印刷品的色彩准确性和一致性。': 'Ensure color accuracy and consistency in printed matter.',
    '可变数据实现每个打印项的个性化和定制。':
        'Variable data enables personalization and customization of each print item.',
    '软包装订单及拼版自动化。': 'Flexible packaging order and assembly automation.',
    '把PDF文件，每页拆分成独立的PDF文件。': 'Split each page of a PDF file into independent PDF files.',
    '按照输入的刀模文件，完成拼版。': 'Complete the imposition according to the input die file.',
    'Tiff文件精度值按照输入参数转换。': 'The Tiff file precision value is converted according to the input parameters.',
    'AI识别图像中的主要实体，完成一键抠图。':
        'AI identifies the main entities in the image and completes one-click cutout.',
    '根据输入的图片，AI生成相似的图片。': 'Based on the input pictures, AI generates familiar pictures.',
    '根据输入的图像，智能扩展生成图片。': 'Intelligent expansion generates pictures based on input images。',
    '根据输入的图像，生成线稿。': 'Based on the input image, generate a line draft.',
    '根据输入的文本，生成图片': 'Generate a picture based on the input text',

    'program.StatusMap.create': 'create',
    'program.StatusMap.doing': 'doing',
    'program.StatusMap.failure': 'fail',
    'program.StatusMap.success': 'success',
    'program.StatusMap.timeout': 'timeout',
    'program.StatusMap.stop': 'Process termination',
    'program.StatusMap.audit_success': 'audit pass',
    'program.StatusMap.audit_failure': 'audit rejection',
    'program.StatusMap.send_success': 'send success'
}
